import { dateFormatISO } from "../utils/dateformat";

class CompraItemXML {
	constructor(item, compra) {
		this.cdseq = item.getAttribute("nItem");
		this.cdempresa = compra.cdempresa;
		this.cdcompra = compra.cdcompra;
		this.cdfornecedor = compra.fornecedor.id || 0;
		this.cdproduto = item.cdproduto;
		this.cdreferencia = item.querySelector("cProd").innerHTML;
		this.cdgrupo  = null;
		this.cdsubgrupo  = null;
		this.codbarra  = null;
		this.descricao = `${item.querySelector("xProd")?.innerHTML}`.toUpperCase().substring(0,100);
		this.ncm = item.querySelector("NCM")?.innerHTML;
		const primeirocfop = `${item.querySelector("CFOP")?.innerHTML}`[0];
		if (primeirocfop == "5") {
			this.cfopentrada = "1"+(`${item.querySelector("CFOP")?.innerHTML}`.substring(1));
		} else if (primeirocfop == "6") {
			this.cfopentrada = "2"+(`${item.querySelector("CFOP")?.innerHTML}`.substring(1));
		} else if (primeirocfop == "7") {
			this.cfopentrada = "3"+(`${item.querySelector("CFOP")?.innerHTML}`.substring(1));
		} else {
			this.cfopentrada = item.querySelector("CFOP")?.innerHTML;
		}
		this.cfop = this.cfopentrada;
		if (compra.fornecedor.uf == compra.empresa.uf) {
			this.cfopinter = "";
			this.cfopesta = this.cfop;
		} else {
			this.cfopinter = this.cfop;
			this.cfopesta = "";
		}
		this.unidade = "UN";
		this.embalagem = `${item.querySelector("uCom")?.innerHTML}`.split("/")[0].substring(0,3) || "UN";
		this.quantidade = Number(item.querySelector("qCom")?.innerHTML || 0);
		this.vlrunitario = Number(item.querySelector("vUnCom")?.innerHTML || 0);
		this.vlrcompra = Number(item.querySelector("vProd")?.innerHTML || 0);
		this.desconto = Number(item.querySelector("vDesc")?.innerHTML || 0);
		// this.percDesc = (Number(this.vlrDesc || 0) * 100) / Number(this.vlrcompra || 0);
		this.icms = Number(item.querySelector("pICMS")?.innerHTML || 0);
		this.valoricms = (Number(item.querySelector("vICMS")?.innerHTML || 0) / (Number(item.querySelector("qCom")?.innerHTML || 1)));
		this.valoricmsst = (Number(item.querySelector("vICMSST")?.innerHTML || 0));
		this.icmsst = (Number(item.querySelector("vICMSST")?.innerHTML || 0) / (Number(item.querySelector("qCom")?.innerHTML || 1))) / (Number(item.querySelector("vUnCom")?.innerHTML || 0)) * 100;
		this.ipi = Number((item.querySelector("imposto")?.querySelector("IPI")?.querySelector("pIPI")?.innerHTML) || 0) || 0;
		
		const vlripi = (
			item.querySelector("imposto")?.querySelector("IPI")?.querySelector("IPITrib")?.querySelector("vIPI")?.innerHTML 
			|| item.querySelector("imposto")?.querySelector("IPI")?.querySelector("vIPI")?.innerHTML || 0);
		this.valoripi = Number(vlripi || 0);
		// this.valoripi = Number((item.querySelector("imposto")?.querySelector("IPI")?.querySelector("vIPI")?.innerHTML) || 0);

		this.pis = Number(item.querySelector("pPIS")?.innerHTML);
		this.valorpis = (Number(item.querySelector("vPIS")?.innerHTML || 0));
		this.cofins = Number(item.querySelector("pCOFINS")?.innerHTML);
		this.valorcofins = (Number(item.querySelector("vCOFINS")?.innerHTML || 0));
		this.codbarraFornecedor = `${item.querySelector("cEANTrib")?.innerHTML}`.substring(0,12);
		this.valorFCPST = Number(item.querySelector("vFCPST")?.innerHTML || 0);
		this.FCPST = Number(item.querySelector("pFCPST")?.innerHTML || 0);
		this.valorfrete = 0;
		this.frete = 0;
		this.cdorigem = item.querySelector("ICMS")?.querySelector("orig").innerHTML;
		this.outros = (Number(item.querySelector("vOutro")?.innerHTML || 0) / Number(item.querySelector("vUnCom")?.innerHTML || 0) * 100).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		this.valoroutros = Number(item.querySelector("vOutro")?.innerHTML || 0).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
		this.outrosrs = "0,00";
		this.custo = 0;
		this.margem = 0;
		this.prazo = "";
		this.cdesc = "";
		this.vista = "";
		this.ativo = "A";
	}
}

export class CompraXML {
	constructor(compra) {
		try {
			this.cdcompra = compra.querySelector("nNF").innerHTML;
			this.confirmada = "A";
			this.tipo = "I";
			this.datacompra = new Date(compra.querySelector("dhEmi").innerHTML).toLocaleDateString("en-CA");
			this.natureza = compra.querySelector("natOp")?.innerHTML;
			const dest = compra.querySelector("dest");
			if (dest) {
				this.empresa = {
					cnpj: (dest).querySelector("CNPJ").innerHTML,
					nome: dest.querySelector("xNome").innerHTML,
					uf: dest.querySelector("UF")?.innerHTML || null,
					endereco: dest.querySelector("xLgr")?.innerHTML || null,
					numero: dest.querySelector("nro")?.innerHTML || null,
					bairro: dest.querySelector("xBairro")?.innerHTML || null,
					nomecidade: dest.querySelector("xMun")?.innerHTML || null,
					cep: dest.querySelector("CEP")?.innerHTML || null,
				};
				if (this.empresa.cnpj.length == 14){
					this.empresa.cnpj = this.empresa.cnpj.substring(0,2)+"."+this.empresa.cnpj.substring(2,5)+"."+this.empresa.cnpj.substring(5,8)+"/"+this.empresa.cnpj.substring(8,12)+"-"+this.empresa.cnpj.substring(12,14);
				} else {
					this.empresa.cnpj = this.empresa.cnpj.substring(0,3)+"."+this.empresa.cnpj.substring(3,6)+"."+this.empresa.cnpj.substring(6,9)+"-"+this.empresa.cnpj.substring(9,12);
				}
			} else {
				this.empresa = {};
			}
			const transp = compra.querySelector("transp");
			if (transp) {
				this.transporte = {
					cnpj: transp.querySelector("CNPJ") ? (transp.querySelector("CNPJ")?.innerHTML || "") : (transp.querySelector("CPF")?.innerHTML || ""),
					nome: (`${transp.querySelector("xNome")?.innerHTML}`.toUpperCase() || ""),
					ierg: (transp.querySelector("IE")?.innerHTML || ""),
					uf: (transp.querySelector("UF")?.innerHTML || ""),
					endereco: (transp.querySelector("xEnder")?.innerHTML || ""),
					nomecidade: (transp.querySelector("xMun")?.innerHTML || ""),
					numero: transp.querySelector("nro")?.innerHTML || "",
					transporte: {
						qtdvolumetransporte: transp.querySelector("qVol")?.innerHTML || "",
						embalagemtransporte: transp.querySelector("esp")?.innerHTML || "",
						pesoliquidotransporte: transp.querySelector("pesoL")?.innerHTML || "",
						pesobrutotransporte: transp.querySelector("pesoB")?.innerHTML || "",
					}
				};
				if (this.transporte.cnpj?.length > 0) {
					if (this.transporte.cnpj.length == 14){
						this.transporte.cnpj = this.transporte.cnpj.substring(0,2)+"."+this.transporte.cnpj.substring(2,5)+"."+this.transporte.cnpj.substring(5,8)+"/"+this.transporte.cnpj.substring(8,12)+"-"+this.transporte.cnpj.substring(12,14);
					} else {
						this.transporte.cnpj = this.transporte.cnpj.substring(0,3)+"."+this.transporte.cnpj.substring(3,6)+"."+this.transporte.cnpj.substring(6,9)+"-"+this.transporte.cnpj.substring(9,12);
					}
				}
				this.modalidadeFrete = transp.querySelector("modFrete")?.innerHTML || null;
			} else {
				this.transporte = {};
				this.modalidadeFrete = null;
			}
			this.datapedcompra = compra.datapedcompra;
			this.cdpedcompra = compra.cdpedcompra;
			this.vlrbrutocompra = Number(compra.querySelector("total")?.querySelector("vProd").innerHTML);
			this.desconto = Number(compra.querySelector("total")?.querySelector("vDesc")?.innerHTML || 0);
			this.frete = Number(compra.querySelector("total")?.querySelector("vFrete")?.innerHTML || 0);
			this.valorFCPST = Number(compra.querySelector("total")?.querySelector("vFCPST")?.innerHTML || 0);
			this.outros = Number(compra.querySelector("total")?.querySelector("vOutro")?.innerHTML || 0);
			this.observa = compra.querySelector("infCpl")?.innerHTML;
			this.basesubst = Number(compra.querySelector("total")?.querySelector("vBCST")?.innerHTML || 0);
			this.seguro = Number(compra.querySelector("total")?.querySelector("vSeg")?.innerHTML || 0);
			this.baseicms = Number(compra.querySelector("total")?.querySelector("vBC")?.innerHTML || 0);
			this.vlripi = Number(compra.querySelector("total")?.querySelector("vIPI").innerHTML || 0);
			this.valorICMSst = Number(compra.querySelector("total")?.querySelector("vST").innerHTML || 0);
			this.valorbrutopedido = Number(compra.querySelector("total")?.querySelector("ICMSTot")?.querySelector("vNF").innerHTML || 0);
			this.valorICMS = Number(compra.querySelector("total")?.querySelector("vICMS").innerHTML || 0);
			this.nfechave = compra.querySelector("infProt")?.querySelector("chNFe").innerHTML;
			this.nfeid = compra.nfeid;
			if (compra.querySelector("emit")) {
				this.fornecedor = {
					nome: `${compra.querySelector("emit")?.querySelector("xNome")?.innerHTML}`.toUpperCase(),
					cnpj: compra.querySelector("emit")?.querySelector("CNPJ")?.innerHTML,
					ie: compra.querySelector("emit")?.querySelector("IE")?.innerHTML,
					fantasia: compra.querySelector("emit")?.querySelector("xFant")?.innerHTML,
					endereco: compra.querySelector("emit")?.querySelector("xLgr")?.innerHTML,
					nro: compra.querySelector("emit")?.querySelector("nro")?.innerHTML,
					bairro: compra.querySelector("emit")?.querySelector("xBairro")?.innerHTML,
					ibge: compra.querySelector("emit")?.querySelector("cMun")?.innerHTML,
					cidade: compra.querySelector("emit")?.querySelector("xMun")?.innerHTML,
					uf: compra.querySelector("emit")?.querySelector("UF")?.innerHTML,
					cep: compra.querySelector("emit")?.querySelector("CEP")?.innerHTML,
					fone: compra.querySelector("emit")?.querySelector("fone")?.innerHTML,
				};
				if (this.fornecedor.cnpj.length == 14){
					this.fornecedor.cnpj = this.fornecedor.cnpj.substring(0,2)+"."+this.fornecedor.cnpj.substring(2,5)+"."+this.fornecedor.cnpj.substring(5,8)+"/"+this.fornecedor.cnpj.substring(8,12)+"-"+this.fornecedor.cnpj.substring(12,14);
				} else {
					this.fornecedor.cnpj = this.fornecedor.cnpj.substring(0,3)+"."+this.fornecedor.cnpj.substring(3,6)+"."+this.fornecedor.cnpj.substring(6,9)+"-"+this.fornecedor.cnpj.substring(9,12);
				}
			} else {
				this.fornecedor = {};
			}
			this.itens = [];
			this.estoque = [];
			const itens = [...compra.querySelectorAll("det")];
			[...itens].forEach(item => this.itens.push({...new CompraItemXML(item, {...this})}));
			[...itens].forEach((item, index) => {
				item.cdseq = (index +1);
				this.estoque.push({...new CompraItemXML(item, {...this})});
			});
			this.financa = [...compra.querySelectorAll("dup")].map(financa => ({
				cddig: 0, // pgto.numeroParcela,
				cddoc: compra.cdcompra || "",
				formaPagamento: null,
				cdtipopgto: "",
				parcela:  financa.querySelector("nDup").innerHTML,
				pagamento: "",
				datadoc: this.datacompra,
				datavenc:  new Date(financa.querySelector("dVenc").innerHTML.split("-")).toLocaleDateString("en-CA"),
				databaixa: (financa.vistaprazo == 1) ? financa.dataBaixa : null,
				debito: (financa.vistaprazo == 1) ? financa.debito : 0,
				contarc: Number(financa.querySelector("vDup").innerHTML),
				observa: financa.observacao,
				xmlfinanca: true,
			}));
			this.original = {...this};
		} catch (error) {
			console.log(error);
			return {error};
		}
	}
}
export class CompraApi {
	constructor(compra) {
		this.cdempresa = compra.cdempresa;
		this.cdcompra = compra.cdcompra || null;
		this.tipo = compra.tipo || "C";
		this.cdfornecedor = compra.fornecedor?.id;
		this.cdpedcompra = compra.cdpedcompra;
		this.cdtransporte = compra.transporte?.id;
		this.datacompra = compra.datacompra;
		this.datapedcompra = compra.datapedcompra;
		this.modelo = "55";
		this.serie = "001";
		this.confirmada = compra.confirmada || "N";
		this.cfop = compra.cfop;
		this.nfeid = compra.nfeid;
		this.nfechave = compra.nfechave;
		this.nomeFornecedor = compra.fornecedor.nome;
		this.vlrbrutocompra = compra.vlrbrutocompra;
		this.desconto = compra.desconto || 0;
		this.frete = compra.frete || 0;
		this.aumento = compra.aumento || 0;
		this.observa = compra.observacao;
		this.valorbrutopedido = typeof compra.valorbrutopedido == "number" ? compra.valorbrutopedido : Number(`${compra.valorbrutopedido}`.replace(".", "").replace(",", ".") || 0);
		this.outros = (typeof compra.outros == "number" ? compra.outros : Number(`${compra.outros}`.replace(".", "").replace(",", ".") || 0)) || 0;
		this.basesubst = (typeof compra.basesubst == "number" ? compra.basesubst : Number(`${compra.basesubst}`.replace(".", "").replace(",", ".") || 0)) || 0;
		this.vlrsubst = (typeof compra.valorICMSst == "number" ? compra.valorICMSst : Number(`${compra.valorICMSst}`.replace(".", "").replace(",", ".") || 0)) || 0;
		this.baseicms = (typeof compra.baseicms == "number" ? compra.baseicms : Number(`${compra.baseicms}`.replace(".", "").replace(",", ".") || 0)) || 0;
		this.vlrfcp = (typeof compra.valorFCPST == "number" ? compra.valorFCPST : Number(`${compra.valorFCPST}`.replace(".", "").replace(",", ".") || 0)) || 0;
		this.icms = (typeof compra.valorICMS == "number" ? compra.valorICMS : Number(`${compra.valorICMS}`.replace(".", "").replace(",", ".") || 0) || 0);
		this.vlripi = (typeof compra.vlripi == "number" ? compra.vlripi : Number(`${compra.vlripi}`.replace(".", "").replace(",", ".") || 0)) || 0;
		this.itens = compra.itens ? compra.itens.map((produto, index) => ({
			cdseq: produto.cdseq || (index + 1),
			codbarraFornecedor: produto.codbarraFornecedor,
			ncm: produto.ncm,
			cdreferencia: produto.cdreferencia,
			cfop: produto.cfopentrada || produto.cfop,
			cofins: produto.cofins,
			cofinsrs: produto.valorcofins,
			descricao: `${produto.descricao}`.substring(0,100),
			frete: typeof(produto.frete) == "number" ? Number(Number(produto.frete).toFixed(2)) : Number(`${produto.frete || 0}`.replace(".", "").replace(",", ".")),
			freters: typeof(produto.valorfrete) == "number" ? Number(Number(produto.valorfrete).toFixed(2)) : Number(`${produto.valorfrete || 0}`.replace(".", "").replace(",", ".")),
			icms: produto.icms,
			icmsrs: produto.valoricms,
			icmssubs: typeof(produto.icmsst) == "number" ? Number(Number(produto.icmsst).toFixed(2)) : Number(`${produto.icmsst || 0}`.replace(".", "").replace(",", ".")),
			icmssubsrs: typeof(produto.valoricmsst) == "number" ? produto.valoricmsst : Number(`${produto.valoricmsst || 0}`.replace(".", "").replace(",", ".")),
			ipi: typeof(produto.ipi) == "number" ? produto.ipi : Number(`${produto.ipi || 0}`.replace(".", "").replace(",", ".")),
			ipirs: produto.valoripi,
			outros: produto.FCPST ? (
				((typeof(produto.valoroutros) == "number" ? produto.valoroutros : Number(`${produto.valoroutros || 0}`.replace(".", "").replace(",", ".")))
                + produto.valorFCPST) / produto.vlrunitario *100
			) : Number(`${produto.outros}`.replace(".", "").replace(",", ".")),
			outrosrs: produto.FCPST ? (
				(typeof(produto.valoroutros) == "number" ? produto.valoroutros : Number(`${produto.valoroutros || 0}`.replace(".", "").replace(",", ".")))
                + produto.valorFCPST
			) : typeof(produto.valoroutros) == "number" ? produto.valoroutros : Number(`${produto.valoroutros || 0}`.replace(".", "").replace(",", ".")),
			pis: produto.pis,
			pisrs: produto.valorpis,
			qtdecompra: typeof(produto.quantidade) == "number" ? produto.quantidade : Number(`${produto.quantidade || 1}`.replace(".", "").replace(",", ".")),
			unidade: produto.unidade,
			vlrcompra: produto.vlrcompra,
			vlrunitario: produto.vlrunitario
		})) : [];

		this.estoque = compra.estoque ? compra.estoque.map((produto, index) => ({
			cdseq: produto.cdseq || (index + 1),
			codbarraFornecedor: produto.codbarraFornecedor,
			cdproduto: produto.cdproduto,
			cdreferencia: produto.cdreferencia,
		})) : [];
        
		this.financeiro = compra.financeiro ? compra.financeiro.map((pgto, index) => ({
			cddig: pgto.cddig || 0,
			cddoc: compra.cdcompra || "",
			cdtipopgto: pgto.cdtipo || (pgto.formaPagamento?.cdtipo || null),
			parcela: pgto.parcela || (index+1),
			pagamento: pgto.pagamento || (pgto.formaPagamento?.descricao),
			datadoc: dateFormatISO({date: null, format: "ISOdate"}),
			datavenc: dateFormatISO({date: null, format: "ISOdate"}),
			databaixa: (pgto.formaPagamento?.vistaprazo == 1 || pgto.formaPagamento?.cartao == "S") ? dateFormatISO({
				date: (pgto.dataBaixa || new Date(
					new Date().getFullYear(),
					new Date().getMonth(),
					new Date().getDate(),
					new Date().getHours() -3,
					new Date().getMinutes(),
					new Date().getMilliseconds()
				).toISOString()),
				format: "ISOdate"
			}) : null,
			debito: (pgto.formaPagamento?.vistaprazo == 1) ? pgto.contarc : 0,
			contarc: (pgto.formaPagamento?.vistaprazo == 1) ? 0 : pgto.contarc,
			observa: pgto.observacao,
		})) : [];
	}
}

export class Compras{
	constructor(compra){
		this.cdempresa = compra.entradaId.cdempresa;
		this.cdcompra = compra.entradaId.cdcompra;
		this.tipo = compra.entradaId.tipo;
		this.cdfornecedor = compra.entradaId.cdfornecedor;
		this.nomefor = compra.nomefor;
		this.cfop = compra.cfop;
		this.datacompra = compra.datacompra;
		this.cdtransporte = compra.cdtransporte;
		this.cdpagto = compra.cdpagto;
		this.vlrbrutocompra = compra.vlrbrutocompra;
		this.valorbrutopedido = compra.valorbrutopedido;
		this.nfeid = compra.nfeid;
		this.confirmada = compra.confirmada;
	}
}