import ExcelJS from "exceljs";

export const gerarRelatorioVendasPorFornecedorXLSX = async (context,{vendas, datas}) => {
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet("RelatorioVendasPorFornecedor");
	let totais = {quantidade: 0, bruto: 0, desconto: 0, troca: 0, valor: 0};
	worksheet.addRow(["Relatorios de Venda por Fornecedor entre "+datas.dataI.split("-").reverse().join("/")+" e "+datas.dataF.split("-").reverse().join("/")]);
	worksheet.addRow(["Venda", "Fornecedor", "Produto", "Qtd.", "$ Custo", "Emissão", "$ Bruto", "$ Desconto", "$ Troca", "$ Valor", "Pagamento"]);
	vendas.forEach(item => {
		worksheet.addRow([
			item.venda,
			(item.codigo?item.codigo+"-":"")+item.fornecedor, item.produ+"-"+item.produto,
			item.quantidade,
			Number((item.custo || 0).toFixed(2)),
			item.emissao.split("-").reverse().join("/"),
			Number((item.bruto || 0).toFixed(2)),
			Number((item.desconto || 0).toFixed(2)),
			Number((item.troca || 0).toFixed(2)),
			Number((item.valor || 0).toFixed(2)),
			item.pagamento
		]);
		totais.quantidade += item.quantidade;
		totais.bruto += Number((item.bruto || 0).toFixed(2));
		totais.desconto += Number((item.desconto || 0).toFixed(2));
		totais.troca += Number((item.troca || 0).toFixed(2));
		totais.valor += Number((item.valor || 0).toFixed(2));
	});
	worksheet.addRow([
		"TOTAL: ",
		"",
		"",
		totais.quantidade,
		"",
		"",
		totais.bruto,
		totais.desconto,
		totais.troca,
		totais.valor,
	]);
	const buffer = await workbook.xlsx.writeBuffer();
	const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
	const fileName = "relatorio.xlsx";
	const downloadLink = document.createElement("a");
	downloadLink.href = window.URL.createObjectURL(blob);
	downloadLink.setAttribute("download", fileName);
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};