var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContainerPage',{attrs:{"id":"container-plano-contas"}},[_c('ContentPage',{staticClass:"body-page"},[_c('Filtro',{attrs:{"mostrarBotaoNovo":true},on:{"filtrar":function($event){return _vm.buscarPlanoContasView()},"limpar":_vm.limparFiltro,"adicionarNovo":function($event){return _vm.abrirModalAdiconarPlano()}}},[_c('div',{staticClass:"filtro-content"},[_c('div',{staticClass:"column-filtro"},[_c('div',{staticClass:"row-filtro"},[_c('div',{staticClass:"input-group group-pesquisar"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],attrs:{"type":"text","placeholder":" ","autocomplete":"off","id":"pesquisaLivre"},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing){ return; }_vm.query=$event.target.value}}}),_c('label',{attrs:{"label":"pesquisaLivre"}},[_vm._v(" Buscar por descrição ")])])])])])]),(_vm.PlanoContasVisualizacao.length && _vm.PlanoContasVisualizacao.length > 0)?_c('div',{staticClass:"container-list"},_vm._l((_vm.PlanoContasVisualizacao),function(pc,index){return _c('span',{key:index,staticClass:"card-pc",style:(("margin-left:" + (_vm.PlanoContasVisualizacao.length && _vm.PlanoContasVisualizacao.length > 0 
						? _vm.verificaPonto(pc, index) : '0') + "px;"))},[_c('div',{staticClass:"pc-nome"},[_vm._v(" "+_vm._s(pc.nome)+" "),_c('i',{staticClass:"fas fa-edit",staticStyle:{"color":"var(--primario)"},on:{"click":function($event){return _vm.editarPlanoConta(pc)}}})]),_c('span',[_vm._v(" "+_vm._s(pc.valor ? pc.valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : null)+" ")])])}),0):_vm._e(),(_vm.PlanoContasVisualizacao && _vm.PlanoContasVisualizacao.length > 0)?_c('div',{staticClass:"totalizadores"},[_c('div',{staticClass:"input-group"},[_c('input',{staticClass:"input-value",attrs:{"type":"text","id":"total-receita","readonly":""},domProps:{"value":_vm._f("formatarParaReais")((_vm.TotalPrevisaoReceita || 0))}}),_c('label',{attrs:{"for":"total-receita"}},[_vm._v(" Total Receita ")])]),_c('div',{staticClass:"input-group"},[_c('input',{staticClass:"input-value",attrs:{"type":"text","id":"total-despesa","readonly":""},domProps:{"value":_vm._f("formatarParaReais")((_vm.TotalPrevisaoDespesa || 0))}}),_c('label',{attrs:{"for":"total-despesa"}},[_vm._v(" Total Despesa ")])]),_c('div',{staticClass:"input-group"},[_c('input',{staticClass:"input-value",attrs:{"type":"text","id":"total-ambos","readonly":""},domProps:{"value":_vm._f("formatarParaReais")((_vm.TotalPrevisaoAmbos || 0))}}),_c('label',{attrs:{"for":"total-ambos"}},[_vm._v(" Total Ambos ")])])]):_vm._e(),_c('Modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.adicionarPlano),expression:"adicionarPlano"}],attrs:{"idModal":'adicionar-plano-conta',"dimensoes":_vm.isMobileDevice ? {
					width: '98vw',
					height: '60vh'
				} : {
					width: '75vw',
					height: 'auto'
				}},on:{"fecharModal":_vm.fecharModal}},[(_vm.adicionarPlano)?_c('AdicionaPlanoContas',{attrs:{"desabilitaTipoConta":_vm.disableInputTipoConta,"EditandoConta":_vm.editandoConta,"PlanoContasProp":_vm.conta},on:{"fecharModal":_vm.fecharModal}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }