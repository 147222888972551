import Venda from "../service/Venda.js";
import Financeiro from "../service/Financeiro.js";
import Usuario from "../service/Usuario.js";
import Vendedor from "../service/Vendedor.js";
import Empresa from "../service/Empresa.js";
import Cliente from "../service/Cliente.js";
import MetodosPagamento from "../service/MetodosPagamento.js";
import Produto from "../service/Produto.js";
import Caixa from "../service/Caixa.js";
import Contas from "../service/Conta.js";
import Relatorio from "../service/Relatorio.js";
import GrupoProduto from "../service/GrupoProduto.js";
import Bandeira from "../service/Bandeira.js";
import Grade from "../service/Grade.js";
import {BASE_URL} from "../configs/params.json";
import Cfop from "../service/Cfop.js";
import entradas from "../service/Entradas.js";
import Promocao from "../service/Promocao.js";
import Consorcio from "../service/Consorcio";
import Cidade from "../service/Cidade.js";
import Paginacao from "../service/Paginacao.js";
import Perfil from "../service/Perfil.js";
import Cargo from "../service/Cargo.js";
import Transferencia from "../service/Transferencia.js";
import Log from "../service/Log.js";
import Pix from "../service/Pix.js";
import Conciliadora from "../service/Conciliadora.js";
import ContaPagar from "../service/ContaPagar.js";
import PrevisaoCompra from "../service/PrevisaoCompra.js";
import PlanoContas from "../service/PlanoContas.js";
import Agenda from "../service/Agenda.js";
import CentroCusto from "../service/CentroCusto.js";
import Dashboard from "../service/Dashboard.js";
import Ncm from "../service/Ncm.js";

let API_URL = process.env.VUE_APP_ENV == "production" ? window.location.origin : BASE_URL;
if (process.env.VUE_APP_API_PATH) {
	API_URL += process.env.VUE_APP_API_PATH+"-"+process.env.VUE_APP_VERSION;
}

export default {
	carregando: false,
	mensagemErro: null,
	Informativo: null,
	checkboxPesquisa: true,
	leitor: null,
	Parametros: null,
	base64: null,
	API_URL,
	versao: process.env.VUE_APP_VERSION,
	etiquetas: [],
	...Venda.state,
	...Financeiro.state,
	...Usuario.state,
	...Vendedor.state,
	...Empresa.state,
	...Cliente.state,
	...Cidade.state,
	...MetodosPagamento.state,
	...Produto.state,
	...Caixa.state,
	...Relatorio.state,
	...GrupoProduto.state,
	...Bandeira.state,
	...Contas.state,
	...Cfop.state,
	...Grade.state,
	...entradas.state,
	...Promocao.state,
	...Consorcio.state,
	...Paginacao.state,
	...Perfil.state,
	...Cargo.state,
	...Transferencia.state,
	...Log.state,
	...Pix.state,
	...Conciliadora.state,
	...ContaPagar.state,
	...PrevisaoCompra.state,
	...PlanoContas.state,
	...Agenda.state,
	...CentroCusto.state,
	...Dashboard.state,
	...Ncm.state,
};
