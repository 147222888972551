<template>
	<article class="GerenciaUsuarios" v-if="!usuarioSelecionado">
		<fieldset id="filtros-fieldset">
			<legend @click="esconderFiltro">
				<i class="fa fa-angle-right" style="color: var(--primario)" v-if="!mostraFiltro"></i>
				<i class="fa fa-angle-down" style="color: var(--primario)" v-else></i>
				Filtros
			</legend>
			<div class="filtro-card-controle">
				<div class="filtro-card-filtros">
					<div class="filtro-card-linha" v-for="(filtro, index) in Object.keys(filtros)" :key="index">
						<div class="filtro-card-linha-item">
							<input type="checkbox" :id="filtros[filtro].campoFiltro" @change="filtrarUsuarios(null)" v-model="filtros[filtro].selecionado" :value="true">
							<label :for="filtros[filtro].campoFiltro">{{ filtro }}</label>
						</div>
						<div class="filtro-card-linha-item">
							<select v-model="filtros[filtro].opcaoSelecionada" @change="selecionouOpcaoFiltro(filtro)">
								<option v-for="(opt, index) in Object.keys(filtros[filtro].opcoes)"
									:key="`option-${index}`" :value="filtros[filtro].opcoes[opt]">{{ opt }}</option>
							</select>
						</div>
					</div>
				</div>
				<div class="filtro-card-busca">
					<div class="filtro-card-linha">
						<div class="filtro-card-linha-item">
							<label for="size">Exibir</label>
							<select id="size" v-model="paginacao.size" @change="filtrarUsuarios(null)">
								<option :value="25">25 itens</option>
								<option :value="50">50 itens</option>
								<option :value="100">100 itens</option>
							</select>
						</div>
						<div class="paginacao" v-if="paginacao.totalPaginas > 0">
							<span v-if="paginacao.paginaAtual > 1" @click="filtrarUsuarios(0)">1</span>
							<span @click="filtrarUsuarios(paginacao.paginaAtual - 1)" v-if="paginacao.paginaAtual > 0">
								{{ paginacao.paginaAtual }}
							</span>
							<span class="ativa">
								{{ paginacao.paginaAtual + 1 }}
							</span>
							<span @click="filtrarUsuarios(paginacao.paginaAtual + 1)"
								v-if="paginacao.paginaAtual + 1 < paginacao.totalPaginas">
								{{ paginacao.paginaAtual + 2 }}
							</span>
							<span v-if="paginacao.paginaAtual + 2 < paginacao.totalPaginas"
								@click="filtrarUsuarios(paginacao.totalPaginas - 1)">{{ paginacao.totalPaginas }}</span>
						</div>
					</div>
					<div class="filtro-card-linha">
						<input type="text" id="pesquisa-livre" placeholder="Busca de usuários" v-model="pesquisaLivre" @input="filtrarUsuarios(null)">
						<button class="botao botao-acao" @click="selecionarUsuario(null)">
							<i class="far fa-plus-square" style="color:var(--acao-texto)"></i>
							<span>Incluir</span>
						</button>
					</div>
				</div>
			</div>
		</fieldset>
		<div class="card-lista-overflow">
			<div class="card-lista">
				<div v-for="usuario in ListaUsuarios.content" :key="usuario.id" class="card-item">
					<div class="foto">
						<i class="icon fas fa-user"></i>
					</div>
					<div class="info">
						<p>{{ usuario.id }} - {{ usuario.nome }}</p>
						<p>Cargo: {{ usuario.cargo }}</p>
						<p>Login: {{ usuario.login }}</p>
						<p>Empresa: {{ usuario.empresa ? (Empresas.find(emp => emp.id == usuario.empresa) ? `${usuario.empresa} - ${$store.state.Empresas.find(emp => emp.id == usuario.empresa).fantasia}` : "") : "MULTI EMPRESA" }}</p>
					</div>
					<div class="icons">
						<i class="icon fas fa-pencil-alt" @click="selecionarUsuario({...usuario})"></i>
						<i class="icon fas fa-key" style="color: var(--cancelar)" @click="mudarSenhaUsuario({...usuario})" v-if="$store.state.Usuario.usuarioPrivilegiado"></i>
						<i class="icon fas fa-user-check" @click="ativarOuDesativarUsuario(usuario)" style="color:var(--confirmar)" v-if="usuario.ativo == 'A'"></i>
						<i class="icon fas fa-user-lock" @click="ativarOuDesativarUsuario(usuario)" style="color:var(--cancelar)" v-else></i>
						<i class="icon fas fa-sign-in-alt" @click="acessarComoUsuario(usuario)" style="color:var(--primario)"
						v-if="$store.state.Usuario.usuarioPrivilegiado && $store.state.Usuario.cargo == 'ADMINISTRADOR'"></i>
					</div>
				</div>
			</div>
		</div>
	</article>
	<article class="GerenciaUsuarios" v-else>
		<div class="dados-container">
			<div class="dados-linha">
				<div class="input-group dados-item">					
					<input v-model="usuarioCadastrar.id" disabled="disabled" type="text" maxlength= "10" id="id" class="input-numero">
					<label for="id">Cód.&nbsp;</label>
				</div>
				<div class="input-group dados-item-linha1">					
					<input v-model="usuarioCadastrar.nome" id="nome" type="text" autocomplete="off" class="input-flex" 
					@keydown.enter="proximoCampo('login')">
					<label for="nome">Nome&nbsp;</label>
				</div>
				<div class="dados-item">
					<div id="ativo" @click="mudarAtivo('ativo', 'A', 'I')">
						<label for="ativo">Ativo:&nbsp;</label>
						<i class="far fa-times-circle" v-if="usuarioCadastrar.ativo == 'I' " style="color: red;"> Não</i>
						<i class="far fa-check-circle" v-else style="color: green"> Sim</i>
					</div>
				</div>
				<div class="input-group dados-item-linha1">					
					<input v-model="usuarioCadastrar.login" type="text" id="login" autocomplete="off" @keydown.enter="proximoCampo('cargos-pesquisa')"
					style="text-transform: lowercase;">
					<label for="login">Login&nbsp;</label>
				</div>
			</div>
			<div class="dados-linha">
				<div class="input-group dados-item">					
					<input
						type="search"
						list="lista-cargos"
						id="cargos-pesquisa"
						@keydown="filtrarCargos"
						@keydown.up.prevent=""
						@keydown.down.prevent=""
						v-model="usuarioCadastrar.cargo" 
						@keydown.enter="proximoCampo('empresas')"
						autocomplete="off"
					>
					<datalist id="lista-cargos" name="lista-cargos">
						<option v-for="cargo in ListaCargos.content" :key="cargo.id" :value="cargo.descricao"></option>
					</datalist>
					<label for="cargos-pesquisa">Cargo&nbsp;</label>
				</div>
				<div class="input-group dados-item">					
					<select v-model="usuarioCadastrar.empresa" name="empresas" id="empresas" @keydown.enter="proximoCampo('perfil-pesquisa')">
						<option :value="null">MULTI EMPRESA</option>
						<option v-for="empresa in Empresas" :key="empresa.id" :value="empresa.id">{{empresa.nome}}</option>
					</select>
					<label for="empresas">Empresas&nbsp;</label>
				</div>
				<div class="input-group dados-item">					
					<input 
						type="search"
						list="lista-perfis"
						id="perfil-pesquisa"
						v-model="pesquisaPerfil"
						@keydown="filtrarPerfis"
						@keydown.up.prevent=""
						@keydown.down.prevent=""
						@input="selecionouPerfil"
						@keydown.enter="proximoCampo('maxDiasTroca')"
					>
					<datalist id="lista-perfis" name="lista-perfis">
						<!-- <option value="Selecionar"></option> -->
						<option v-for="perfil in ListaPerfis.content" :key="perfil.id" :value="perfil.descricao"></option>
					</datalist>
					<label for="perfil-pesquisa">Perfil&nbsp;</label>
				</div>
			</div>
			<div class="dados-linha wrap">
				<div class="input-group dados-item-linha3">					
					<input v-model="usuarioCadastrar.maxDiasTroca" type="text" id="maxDiasTroca"
					@keydown.enter="proximoCampo('maxNumeroParcelas')">
					<label for="maxDiasTroca" v-if="ehcelular()">Prazo troca&nbsp;</label>
					<label for="maxDiasTroca" v-if="!ehcelular()">Prazo para filtrar troca&nbsp;</label>
					
				</div>
				<div class="input-group dados-item-linha3">					
					<input v-model="usuarioCadastrar.maxNumeroParcelas" type="text" id="maxNumeroParcelas"
					@keydown.enter="proximoCampo('maxPorcentagemDesconto')">
					<label for="maxNumeroParcelas" v-if="ehcelular()">Máx. parcelas&nbsp;</label>
					<label for="maxNumeroParcelas" v-if="!ehcelular()">Número máximo de parcelas&nbsp;</label>
				</div>
				<div class="input-group dados-item-linha3">					
					<input v-model="usuarioCadastrar.maxPorcentagemDesconto" type="text" id="maxPorcentagemDesconto"
					@keydown.enter="proximoCampo('minValorParcela')">
					<label for="maxPorcentagemDesconto" v-if="ehcelular()">% Máx. desconto&nbsp;</label>
					<label for="maxPorcentagemDesconto" v-if="!ehcelular()">% máxima de desconto&nbsp;</label>
				</div>
				<div class="input-group dados-item-linha3">					
					<input v-model="usuarioCadastrar.minValorParcela" type="text" class="input-numero" id="minValorParcela">
					<label for="minValorParcela" v-if="ehcelular()">Valor mín. parcela&nbsp;</label>
					<label for="minValorParcela" v-if="!ehcelular()">Valor mínimo por parcela&nbsp;</label>
				</div>
			</div>
		</div>
		<ParametrosUsuarios :Parametro="usuarioCadastrar.parametros"  @passarDados="preencherParametros" class="parametro" />
		<div class="botoes buttons">
			<button class="botao botao-cancelar" @click="zerarData"><i class="fa fa-times icone-cancelar"></i>Cancelar <span>(Alt + c)</span> </button>
			<button class="botao botao-adicionar" @click="salvar"><i class="fa fa-check icone-salvar"></i>Salvar <span>(Alt + s)</span></button>
		</div>
	</article>
</template>

<script>
import ParametrosUsuarios from "../components/ParametrosUsuarios.vue";

export default {
	name: "GerenciaUsuarios",
	components:{
		ParametrosUsuarios,
	},
	computed: {
		ListaUsuarios() {
			return this.$store.state.ListaUsuarios;
		},
		Empresa(){
			return this.$store.state.Empresa;
		},
		Empresas(){
			return this.$store.state.Empresas;
		},
		ListaPerfis(){
			return this.$store.state.ListaPerfis;
		},
		ListaCargos(){
			return this.$store.state.ListaCargos;
		},
	},
	data() {
		return {
			// Consulta
			usuarioSelecionado: null,
			timer: null,
			timerFilroCargos: null,
			timerFiltroUsuario: null,
			mostraFiltro: true,
			pesquisaLivre: "",
			paginacao: {
				size: 25,
				paginaAtual: 0,
				totalPaginas: 0
			},
			// Cadastro
			pesquisaPerfil: "",
			usuarioCadastrar: {
				id: null,
				nome: "",
				login: "",
				ativo: "A",
				cargo: "",
				empresa: null,
				maxDiasTroca: "",
				maxNumeroParcelas: "",
				maxPorcentagemDesconto: "",
				minValorParcela: "",
				parametros: {}
			},
			filtros: {
				"Situação": {
					campoFiltro: "situacao",
					opcoes: {
						"Ativo": "A",
						"Inativo": "I",
						"Ambos": null,
					},
					selecionado: false,
					opcaoSelecionada: null
				},
			},
		};
	},

	methods: {
		proximoCampo(idCampo) {
			setTimeout(() => {
				const campo = document.getElementById(idCampo);
				if (campo?.focus) {
					campo.focus();
					if (campo.select) {
						campo.select();
					}
				}
			}, 10);
		},
		selecionouOpcaoFiltro(filtro) {
			this.filtros[filtro].selecionado = true;
			this.filtrarUsuarios(null);
		},
		selecionarUsuario(usuario) {
			if (!usuario) {
				this.usuarioSelecionado = {novo: true};
			} else {
				this.usuarioSelecionado = {...usuario};
				this.usuarioCadastrar.cdperfil = this.usuarioSelecionado.cdperfil;
				this.usuarioCadastrar.id = this.usuarioSelecionado.id;
				this.usuarioCadastrar.nome = this.usuarioSelecionado.nome;
				this.usuarioCadastrar.ativo = this.usuarioSelecionado.ativo;
				this.usuarioCadastrar.login = this.usuarioSelecionado.login;
				this.usuarioCadastrar.empresa = this.usuarioSelecionado.empresa;
				this.usuarioCadastrar.cargo = this.usuarioSelecionado.cargo;
				this.usuarioCadastrar.maxNumeroParcelas = this.usuarioSelecionado.maxNumeroParcelas != null ? `${this.usuarioSelecionado.maxNumeroParcelas}` : "0";
				this.usuarioCadastrar.maxDiasTroca = this.usuarioSelecionado.maxDiasTroca != null ? `${this.usuarioSelecionado.maxDiasTroca}` : "0";
				this.usuarioCadastrar.maxPorcentagemDesconto = Number(this.usuarioSelecionado.maxPorcentagemDesconto).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.usuarioCadastrar.minValorParcela = Number(this.usuarioSelecionado.minValorParcela).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
				this.usuarioCadastrar.parametros = {...this.usuarioSelecionado.parametros};
				this.pesquisaPerfil = this.ListaPerfis.content.find(pf => pf.id === this.usuarioCadastrar.cdperfil)?.descricao;

				// setTimeout(() => {
				// 	this.$bus.$emit("preencherParametros", this.usuarioCadastrar.parametros);
				// },50);
			}
		},
		esconderFiltro() {
			let elemento = document.getElementById("filtros-fieldset");
			elemento = elemento.children[1];
			if (elemento.classList.contains("esconder")) {
				elemento.classList.remove("oculto");
				setTimeout(() => {
					elemento.classList.remove("esconder");
				}, 255);
				this.mostraFiltro = true;
			} else {
				elemento.classList.add("esconder");
				this.mostraFiltro = false;
				setTimeout(() => {
					elemento.classList.add("oculto");
				}, 255);
			}
		},
		async filtrarUsuarios(novaPagina) {
			clearTimeout(this.timerFiltroUsuario);
			this.timerFiltroUsuario = setTimeout(async () => {
				let filtro = {
					page: novaPagina >= 0 ? novaPagina : this.paginacao.paginaAtual,
					size: this.paginacao.size || this.ListaUsuarios.size,
					query: this.pesquisaLivre,
					filtroDinamico: {}
				};
				Object.keys(this.filtros).filter(key => this.filtros[key].selecionado).forEach(key => {
					if (this.filtros[key].opcaoSelecionada != "Selecione" && this.filtros[key].opcaoSelecionada != null) {
						filtro.filtroDinamico[this.filtros[key].campoFiltro] = this.filtros[key].opcaoSelecionada;
					}
				});
				await this.$store.dispatch("filtrarUsuarios", filtro);
				this.paginacao.size = this.ListaUsuarios.size;
				this.paginacao.paginaAtual = this.ListaUsuarios.paginaAtual;
				this.paginacao.totalPaginas = this.ListaUsuarios.totalPaginas;
			}, 420);
		},
		mudarAtivo(campo, letra1, letra2){
			if(this.usuarioCadastrar[campo] == letra1){
				this.usuarioCadastrar[campo] = letra2;
			}else{
				this.usuarioCadastrar[campo] = letra1;
			}
		},
		preencherParametros(param){
			this.usuarioCadastrar.parametros = param;
		},
		mudarSenhaUsuario(usuario) {
			if (!usuario.login) return this.$store.commit("relatarErro", {
				mensagem: "Usuário sem login cadastrado, altere o usuário e crie um login para ele!"
			});
			this.$router.push({name: "TrocarSenha", params: {usuario}});
		},
		ativarOuDesativarUsuario(usuario){
			var ativoInativo = usuario.ativo == "A" ? "desativar" : "ativar";
			this.$store.commit("relatarErro",{
				mensagem: `Tem certeza que deseja ${ativoInativo} o usuario ${usuario.nome}?`,
				tipo: "polar",
				item: {
					desativarUsuario: usuario.ativo == "A",
					ativarUsuario: usuario.ativo == "I",
					usuario
				}
			});
		},
		async confirmouPolar(item){
			if(item.desativarUsuario){
				await this.$store.dispatch("atualizarUsuario", {
					...item.usuario,
					ativo: "I",
				});
			}else if(item.ativarUsuario){
				await this.$store.dispatch("atualizarUsuario", {
					...item.usuario,
					ativo: "A",
				});
			}
			this.filtrarUsuarios(0);
		},
		zerarData() {
			this.usuarioSelecionado = null;
			this.usuarioCadastrar = {
				id: null,
				nome: "",
				login: "",
				ativo: "A",
				cargo: "",
				empresa: null,
				maxDiasTroca: "",
				maxNumeroParcelas: "",
				maxPorcentagemDesconto: "",
				minValorParcela: "",
				parametros: {}
			};
			this.pesquisaPerfil = "";

		},
		async salvar() {
			try {
				//if (this.$store.state.Usuario.cargo != "ADMINISTRADOR") {
				if (!this.usuarioCadastrar.login ? true : `${this.usuarioCadastrar.login}`.length < 2) {
					throw new Error("O login precisa ter no mínimo dois caracteres!");
				}
				if (!this.usuarioCadastrar.nome ? true : `${this.usuarioCadastrar.nome}`.length < 2) {
					throw new Error("Favor informar o nome do usuário e tente novamente!");
				}
				const indexCargo = this.ListaCargos.content.map(cargo => cargo.descricao).indexOf(this.usuarioCadastrar.cargo);
				if (!this.usuarioCadastrar.cargo || (indexCargo < 0 && this.usuarioCadastrar.cargo !=="ADMINISTRADOR")) {
					throw new Error("Favor selecionar o cargo do usuário e tente novamente!");
				}
				if (!Object.keys(this.usuarioCadastrar.parametros).length) {
					throw new Error("Favor verificar os parâmetros do usuário e tente novamente!");
				}
				let usuarioCadastrado = null;
				if (!this.usuarioSelecionado.id) {
					const perfil = this.ListaPerfis.content.find(pf => pf.descricao === this.pesquisaPerfil);	
					if(perfil){
						this.usuarioCadastrar.cdperfil = perfil.id;
					}
					usuarioCadastrado = await this.$store.dispatch("cadastrarUsuario", {...this.usuarioCadastrar});
				} else {
					const perfil = this.ListaPerfis.content.find(pf => pf.descricao === this.pesquisaPerfil);	
					if(perfil){
						this.usuarioCadastrar.cdperfil = perfil.id;
					}
					usuarioCadastrado = await this.$store.dispatch("atualizarUsuario", {
						...this.usuarioCadastrar,
						id: this.usuarioSelecionado.id
					});
				}
				if (usuarioCadastrado?.id) {
					await this.filtrarUsuarios();
					this.zerarData();
				}
			} catch (error) {
				this.$store.commit("relatarErro", {mensagem: error.message});
			}
		},

		async filtrarPerfis() {
			clearTimeout(this.timer);
			this.timer = setTimeout(async () => {
				let filtro = {
					page:  0,
					size: this.ListaPerfis.size,
					query: this.pesquisaPerfil,
					filtroDinamico: {}
				};
				await this.$store.dispatch("filtrarPerfis", filtro);
			}, 420);
		},
		selecionouPerfil() {
			clearTimeout(this.timer);
			const perfilSelecionado = this.ListaPerfis.content.find(perfil => perfil.descricao == this.pesquisaPerfil);
			if (!perfilSelecionado) return;
			if (!perfilSelecionado.parametros) return;
			Object.keys(perfilSelecionado.parametros).forEach(key => {
				this.usuarioCadastrar.parametros[key] = perfilSelecionado.parametros[key];
			});
			this.usuarioCadastrar.maxDiasTroca = perfilSelecionado.maxDiasTroca;
			this.usuarioCadastrar.maxNumeroParcelas = perfilSelecionado.maxNumeroParcelas;
			this.usuarioCadastrar.maxPorcentagemDesconto = Number(perfilSelecionado.maxPorcentagemDesconto).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.usuarioCadastrar.minValorParcela = Number(perfilSelecionado.minValorParcela).toLocaleString("pt-BR", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2});
			this.$bus.$emit("preencherParametros", this.usuarioCadastrar.parametros);
		},
		async filtrarCargos(evento) {
			if (evento ? evento.key == "Enter" : false)  {
				evento.preventDefault();
			}
			clearTimeout(this.timerFilroCargos);
			this.timerFilroCargos = setTimeout(async () => {
				let filtro = {
					page:  0,
					size: this.ListaCargos.size,
					query: this.usuarioCadastrar.cargo,
					filtroDinamico: {}
				};
				await this.$store.dispatch("filtrarCargos", filtro);
				const opcoes = {
					"Todos": null
				};
				this.ListaCargos.content.forEach(item => {
					opcoes[item.descricao] = item.descricao;
				});
				if (this.$store.state.Usuario.cargo && (this.ListaCargos.content.map(cargo => cargo.descricao).indexOf(this.$store.state.Usuario.cargo) < 0)) {
					opcoes[this.$store.state.Usuario.cargo] = this.$store.state.Usuario.cargo;
				}
				this.atualizarFiltroDinamico({
					textoFiltro: "Cargo",
					campoFiltro: "cargo",
					opcoes,
					opcaoSelecionada: null
				});
			}, 420);
		},
		atualizarFiltroDinamico({textoFiltro, campoFiltro, opcoes, opcaoSelecionada, selecionado}) {
			this.filtros[textoFiltro] = {
				campoFiltro,
				opcoes,
				selecionado: selecionado || false,
				opcaoSelecionada
			};
			this.$forceUpdate();
		},

		acessarComoUsuario(usuario) {
			this.$store.dispatch("acessarUsuario", usuario);
		},

		async buscarEmpresas() {
			await this.$store.dispatch("buscarEmpresas", {});
			const opcoes = {
				"TODAS": null
			};
			this.Empresas.forEach(item => {
				opcoes[`${item.id} - ${item.fantasia}`] = item.id;
			});
			this.atualizarFiltroDinamico({
				textoFiltro: "Empresa",
				campoFiltro: "empresa",
				opcoes,
				selecionado: false,
				opcaoSelecionada: this.Empresa.id
			});
		},
		ehcelular(){
			if(screen.width < 450){
				return true;
			}
			return false;
		}
	},
	mounted(){
		this.$bus.$on("confirmarPolar", this.confirmouPolar);
	},

	beforeMount() {
		this.buscarEmpresas();
		this.filtrarCargos();
		this.$store.dispatch("buscarPerfis", {});
		this.filtrarUsuarios();
	},

	beforeDestroy() {
		this.$store.commit("limparListaUsuarios");
		this.$bus.$off("confirmarPolar", this.confirmouPolar);
	}
};
</script>

<style scoped>
.GerenciaUsuarios {
	display: flex;
	flex-direction: column;
	padding: 10px;
	flex-grow: 1;
	height:100%;
	overflow-y: hidden;
}

#filtros-fieldset {
	padding-left: 10px;
	border-left: none;
	border-right: none;
	border-bottom: none;
	border-color: var(--primario);
}

#filtros-fieldset legend {
	padding: 0px 5px;
}

.filtro-card-controle {
	display: flex;
	flex-direction: row;
	transition-duration: 250ms;
	transition-property: all;
	transition-timing-function: ease-in-out;
	opacity: 1;
	align-items: center;
}

#filtros-fieldset legend {
	cursor: pointer;
	user-select: none;
}

.filtro-card-controle.esconder {
	opacity: 0;
	transition-duration: 250ms;
	transition-property: all;
	transition-timing-function: ease-in-out;
}

.esconder.oculto {
	display: none;
	transition-delay: 250ms;
}

.filtro-card-filtros {
	display: flex;
	flex-direction: column;
	margin: 0px 5px;
}

.filtro-card-linha {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 5px 0px;
}

.filtro-card-linha .filtro-card-linha-item:nth-child(1) {
	min-width: 200px;
	max-width: 300px;
	flex-grow: 1;
}

.filtro-card-linha-item {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.filtro-card-linha-item input[type="checkbox"] {
	margin: 0px 5px;
	cursor: pointer;
}

.filtro-card-linha-item label {
	margin: 0px 5px;
	cursor: pointer;
}

.filtro-card-linha-item select {
	background-color: var(--bg-app);
	border: none;
	border-radius: 5px;
	box-shadow: 1px 1px 3px #292929;
	text-align: left;
	max-width: 550px;
	user-select: none;
}
.filtro-card-linha-item select:focus {
	user-select: none;
	border: 1px solid var(--primario);
}

.filtro-card-busca {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0px;
	flex-wrap: wrap;
	flex-grow: 1;
}
.filtro-card-busca .filtro-card-linha:nth-child(2) {
	flex-grow: 1;
}
.filtro-card-busca #pesquisa-livre {
	margin: 0px 5px;
	min-width: 280px;
	width: 100%;
}

.paginacao {
	margin: 0px 5px;
}

.icon {
	color: #000;
}

.card-lista-overflow {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow-y: auto;
	padding-bottom: 10px;
}

.card-lista {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
	margin: 0px 5px;
}

.card-lista .card-item {
	display: flex;
	flex-direction: row;
	padding: 20px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	height: auto;
	overflow: hidden;
	border-radius: 1rem;
	box-shadow: 0 0.1rem 0.5rem rgb(0 0 0 / 30%);
}
.card-lista .card-item:hover {
	box-shadow: 0.2rem 0.2rem 0.5rem rgb(0 0 0 / 50%);
}
.card-lista .card-item .info {
	text-align: left;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin: 0px 2px;
}
.card-lista .card-item p {
	font-size: 18px;
	cursor: default;
	
}
.card-lista .card-item .foto {
	margin: 5px;
}
.card-lista .card-item .foto i {
	font-size: 2rem;
}
.card-lista .card-item .icons {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 5px;
	opacity: 0;
	transition-duration: 220ms;
	transition-property: all;
	transition-timing-function: ease-in-out;
}
.card-lista .card-item:hover .icons {
	opacity: 1;
}

.card-lista .card-item .icons i {
	margin: 0.3rem;
	justify-content: flex-end;
	cursor: pointer;
	font-size: 1.5rem;
}

.card-lista .card-item .icons i:nth-child(1) {
	color: var(--confirmar);
}

.card-lista .card-item .icons i:nth-child(2) {
	color: var(--confirmar);
}

.card-lista .card-item .icons i:nth-child(3) {
	color: var(--primario);
}

/* CADASTRO */
.dados-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
}
.dados-container .dados-linha {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}
.dados-container .dados-item {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: auto;
	margin: 5px;
	white-space: nowrap;
}
.dados-container .dados-item-linha3 {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 5px;
	white-space: nowrap;
	width: 24.2%;
}
.dados-container .dados-item-linha1 {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 5px;
	white-space: nowrap;
	width: 40.9%;
}
.dados-container .dados-linha #ativo{
	width: auto;
	flex-wrap: nowrap;
	white-space: nowrap;
}
.dados-container .dados-linha:nth-child(1) .dados-item:nth-child(2) {
	width: 100%;
}
.dados-container .dados-linha:nth-child(2) .dados-item,
.dados-container .dados-linha:nth-child(2) .dados-item input,
.dados-container .dados-linha:nth-child(2) .dados-item select {
	flex-grow: 1;
}
.dados-container .dados-linha .dados-item .input-numero {
	max-width: 90px;
	min-width: 60px;
}
.dados-container .dados-linha .dados-item.wrap {
	flex-wrap: wrap;
}
.dados-container .input-flex {
	width: 100%;
	text-transform: uppercase;
}
.buttons {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-around;
	margin-top: 5px;
}
.buttons .icone-salvar{
	color:#198a19;
}
.buttons .icone-cancelar{
	color:#d11515;
}
.buttons button{
	width: 100%;
	margin: 0px 10px;
}

@media screen and (max-width: 1300px) {
	.card-lista {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 915px) {
	.dados-container .dados-linha:nth-child(1)  {
		flex-wrap: wrap;
	}
	.dados-container .dados-linha:nth-child(1) .dados-item:nth-child(2) {
		flex-grow: 1;
		width: auto;
		order: 1;
	}
	.dados-container .dados-linha:nth-child(1) .dados-item:nth-child(4),
	.dados-container .dados-linha:nth-child(1) .dados-item:nth-child(4) input {
		width: 100%;
		order: 2;
	}
}

@media screen and (max-width:768px) {
	.card-lista {
		display: block !important;
	}
	.filtro-card-controle {
		flex-wrap: wrap;
	}

	.dados-container .dados-linha:nth-child(2) {
		flex-wrap: wrap;
	}
}

@media screen and (max-width: 652px) {
	.filtro-card-filtros {
		flex-grow: 1;
	}
	.filtro-card-filtros .filtro-card-linha-item:nth-child(2) {
		flex-grow: 1;
		justify-content: flex-end;
	}
	.filtro-card-busca #pesquisa-livre {
		min-width: unset;
	}
	.filtro-card-linha .filtro-card-linha-item:nth-child(1) {
		min-width: unset;
	}
	.buttons button i{
		display: none;
	}
	.buttons{
		align-items: center;
	}

	.buttons button span{
		display: none;
	}
}
@media screen and (max-width: 430px){
	.container-campos{
		height: 580vh;
	}
}

@media screen and (max-width: 450px) {
	label, input {
		font-size: 14px;
		height: 20px;
	}
	.dados-container .dados-linha:nth-child(1) .dados-item-linha1{
		width: 64%;
	}
	.dados-container .dados-linha:nth-child(1) .dados-item{
		width: 30%;
	}
	.dados-container .dados-linha:nth-child(2) .dados-item{
		width: 30%;
	}
	.dados-container .dados-linha:nth-child(3) .dados-item-linha3{
		width: 46%;
	}
}

@media screen and (max-width: 400px) {
	.filtro-card-busca .filtro-card-linha:nth-child(1) {
		flex-wrap: wrap;
	}
	.filtro-card-busca .filtro-card-linha:nth-child(1) .paginacao {
		justify-content: flex-start;
		margin: 0px;
		margin-top: 5px;
		width: 100%;
	}

	label, input {
		font-size: 14px;
		height: 20px;
	}
	.dados-container .dados-linha:nth-child(1) .dados-item-linha1{
		width: 64%;
	}
	.dados-container .dados-linha:nth-child(1) .dados-item{
		width: 30%;
	}
	.dados-container .dados-linha:nth-child(2) .dados-item{
		width: 30%;
	}
	.dados-container .dados-linha:nth-child(3) .dados-item-linha3{
		width: 46%;
	}
}
</style>