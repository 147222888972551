<template>
	<div id="MenuLateral">
		<i class="fas fa-eject" @click="$emit('abrirMenu')"></i>
		<div class="menu-scroll">
			<div :class="`menu-item ${menuAtivo == key ? 'menu-ativo' : ''}`" v-for="key in Object.keys(itensMenu)" :key="key" @click="mudarItemMenu(key)" :id="key">
				<i :class="itensMenu[key].iconeClass"></i>
				<span>{{itensMenu[key].texto}}</span>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "MenuLateral",
	props: {
		tipo: String
	},
	computed: {
		menuAtivo() {
			const { menu } = this.$route.query;
			if (menu) return menu;
			if (this.$route.name == "Principal") {
				return "VendasEmAberto";
			}
			return "";
		},
		itensMenu() {
			const itensMostrar = {};
			if (Object.keys(this.menu).includes(this.tipo)) {
				Object.keys(this.menu[this.tipo]).forEach(key => {
					if (this.menu[this.tipo][key].condicao) {
						itensMostrar[key] = this.menu[this.tipo][key];
					}
				});
			}
			return itensMostrar;
		}
	},
	data() {
		return {
			menu: {
				"Gerenciamento": {
					"Clientes": {
						texto: "Clientes",
						iconeClass: "fas fa-user-edit",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado || this.$store.state.Usuario.incluiCliente
					},
					"Vendedores": {
						texto: "Vendedores",
						iconeClass: "fas fa-users",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado || this.$store.state.Usuario.incluiVendedor
					},
					"Produtos": {
						texto: "Produtos",
						iconeClass: "fas fa-boxes",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado || this.$store.state.Usuario.incluiProduto
					},
					"Promocao": {
						texto: "Promoção",
						iconeClass: "fas fa-tags",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado || this.$store.state.Usuario.incluiProduto
					},
					"Grupos": {
						texto: "Grupos",
						iconeClass: "fas fa-object-ungroup",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado || this.$store.state.Usuario.incluiProduto
					},
					"Compras": {
						texto: "Compras",
						iconeClass: "fas fa-hands-helping",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado || this.$store.state.Usuario.incluiCompra
					},
					"PrevisaoCompra": {
						texto: "Previsão de compra",
						iconeClass: "fas fa-file-signature",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado || this.$store.state.Usuario.incluiCompra
					},
					"Consorcio": {
						texto: "Consorcio",
						iconeClass: "fas fa-hand-holding-usd",
						condicao: true
					},
					"Credito": {
						texto: "Crédito Cliente",
						iconeClass: "fas fa-wallet",
						condicao: this.$store.state.Usuario.supervisor
					},
					"Caixas": {
						texto: "Caixas",
						iconeClass: "fas fa-cash-register",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado || this.$store.state.Usuario.incluiConta
					},
					"Contas": {
						texto: "Contas",
						iconeClass: "fas fa-file-invoice-dollar",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado || this.$store.state.Usuario.incluiConta
					},
					"FormasPagamento": {
						texto: "Formas de pagamento",
						iconeClass: "fas fa-dollar-sign",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado || this.$store.state.Usuario.incluiFormaPagamento
					},
					"TransferirEstoque": {
						texto: "Transferir Estoque",
						iconeClass: "fas fa-dolly",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado || this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"CadastroClassificacao": {
						texto: "Classificação",
						iconeClass: "fas fa-clipboard-list",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado
					},
					"Bandeiras": {
						texto: "Bandeiras",
						iconeClass: "fas fa-credit-card",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado || this.$store.state.Usuario.incluiConta
					},
					"Conciliadora": {
						texto: "Conciliadora",
						iconeClass: "fas fa-money-check-alt",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado || this.$store.state.Usuario.caixa || this.$store.state.Usuario.incluiConta
					},
					"ConsultaPix": {
						texto: "Consultar Pix",
						iconeClass: "fas fa-funnel-dollar",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado
					},
					"ConsultarNcm": {
						texto: "Consultar NCM",
						iconeClass: "fas fa-puzzle-piece",
						condicao: true
					}

				},
				"Principal": {
					"VendasEmAberto": {
						texto: "Vendas em aberto",
						iconeClass: "fas fa-hand-holding-usd",
						condicao: true
					},
					"IniciarVenda": {
						texto: "Iniciar venda",
						iconeClass: "fas fa-dollar-sign",
						condicao: true
					},
					"IniciarCondicional": {
						texto: "Iniciar condicional",
						iconeClass: "fas fa-shopping-bag",
						condicao: true
					},
					"IniciarTroca": {
						texto: "Iniciar troca",
						iconeClass: "fas fa-exchange-alt",
						condicao: this.$store.state.Usuario.maxDiasTroca > 0
					},
					"ContasReceber": {
						texto: "Contas a receber",
						iconeClass: "fas fa-file-invoice-dollar",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"Produtos": {
						texto: "Produtos",
						iconeClass: "fas fa-boxes",
						condicao: true
					},
					"Clientes": {
						texto: "Clientes",
						iconeClass: "fas fa-address-book",
						condicao: true
					},
					"Sangria": {
						texto: "Iniciar Sangria",
						iconeClass: "fas fa-money-check-alt",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"Recarga": {
						texto: "Recarregar caixa",
						iconeClass: "fas fa-donate",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"Caixa": {
						texto: this.$store.state.Usuario.supervisor ? "Consulta de caixas" : "Fechar Caixa",
						iconeClass: "fas fa-cash-register",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"GerenciarVendas": {
						texto: "Gerenciar vendas",
						iconeClass: "fas fa-clipboard",
						condicao: true
					},
				},
				"Relatorio": {
					"Dashboard": {
						texto: "Dashboard",
						iconeClass: "fas fa-chart-pie",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado
					},
					"PosicaoEstoque": {
						texto: "Posição de Estoque",
						iconeClass: "fas fa-map-marked",
						condicao: this.$store.state.Usuario.usuarioPrivilegiado
					},
					"Comissao": {
						texto: "Comissão",
						iconeClass: "fas fa-user-edit",
						condicao: true
					},
					"Caixas": {
						texto: "Caixas",
						iconeClass: "fas fa-cash-register",
						condicao: this.$store.state.Usuario.supervisor
					},
					"Contas": {
						texto: "Contas a receber",
						iconeClass: "fas fa-file-invoice-dollar",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"Produtos": {
						texto: "Produtos vendidos",
						iconeClass: "fas fa-boxes",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"LucroProduto": {
						texto: "Desempenho de Produtos",
						iconeClass: "fas fa-coins",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"Estoque": {
						texto: "Saldo de estoque",
						iconeClass: "fas fa-warehouse",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"Entrada": {
						texto: "Entrada de Produtos",
						iconeClass: "fas fa-store",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"Fornecedor": {
						texto: "Histórico de vendas",
						iconeClass: "fas fa-truck-loading",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"HistoricoProduto": {
						texto: "Histórico de produto",
						iconeClass: "fas fa-history",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"LotesVencimento": {
						texto: "Lotes por Vencimento",
						iconeClass: "fas fa-user-clock",
						condicao: true,
					},
					"FaturamentoDiasXHoras": {
						texto: "Faturamento Dias X Horas",
						iconeClass: "fas fa-calendar-check",
						condicao: true,
					},
					"Aniversarios": {
						texto: "Aniversários",
						iconeClass: "fas fa-calendar-day",
						condicao: true,
					}
				},
				"Financeiro": {
					"ContasPagar": {
						texto: "Contas pagar",
						iconeClass: "fas fa-minus",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"Despesas": {
						texto: "Despesas",
						iconeClass: "fas fa-user-minus",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"Receitas": {
						texto: "Receitas",
						iconeClass: "fas fa-user-plus",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"PlanoContas": {
						texto: "Plano de contas",
						iconeClass: "fas fa-file-invoice",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"AgendaContas": {
						texto: "Agenda",
						iconeClass: "far fa-calendar-alt",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"CentroCustos": {
						texto: "Centro de custo",
						iconeClass: "fas fa-receipt",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa
					},
					"Extrato":{
						texto: "Extrato",
						iconeClass: "fas fa-exchange-alt fa-rotate-90",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa,
					},
					"Importacao": {
						texto: "Importação",
						iconeClass: "fas fa-vector-square",
						condicao: this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa,
					}
				}
			}
		};
	},
	methods: {
		mudarItemMenu(id) {
			if (id != this.menuAtivo || Object.keys(this.$route.query).filter(key => key != "menu").length > 0) {
				const itensMenu = document.querySelectorAll("div.menu-item");
				itensMenu.forEach(el => {
					el.classList.remove(["menu-ativo"]);
				});
				document.getElementById(id).classList.add(["menu-ativo"]);
				this.$router.replace({name: this.$route.name, query: {menu: id}});
				this.$emit(`consultar${id}`);
				this.$bus.$emit("limparFiltrosGerenciamento");
			}
		},
		abrirMenu() {
			document.querySelector("i.fas.fa-eject").style.transform = document.querySelector("div.menu-scroll").classList.contains("open-menu") ?
				"rotate(90deg)" : "rotate(-90deg)";
			document.querySelector("div.menu-scroll").classList.toggle("open-menu");
		},
	},
	mounted() {
		this.$bus.$on("mudarItemMenu", this.mudarItemMenu);
		if (!Object.keys(this.itensMenu).includes(this.menuAtivo)) {
			this.$router.replace({name: "Gerenciamento", query: {menu: Object.keys(this.itensMenu)[0]}});
		}
	},
	destroyed() {
		this.$bus.$off("mudarItemMenu", this.mudarItemMenu);
	}
};
</script>

<style scoped>
span{
	font-size: 14px;
}
#MenuLateral {
	grid-area: menu;
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 10vh;
	left: 0px;
	width: 120px;
	bottom: 0px;
	z-index: 2;
}

.menu-scroll {
	width: 100%;
	height: 98%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	margin: auto;
	position: relative;
}

/* MENUS */
.fas.fa-eject {
	z-index: 3;
	display: none;
	padding: 5px;
	color: var(--bg-primario);
	font-size: 14pt;
	position: fixed;
	top: 9vh;
	left: 4px;
	transform: rotate(90deg);
	transition: all 1s;
}

.menu-item {
	width: 100%;
	height: 80px;
	margin: 10px 0px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: rgba(0, 0, 0, 0.4);
	position: relative;
	text-align: center;
}

.menu-item i {
	color: rgba(0, 0, 0, 0.4);
	font-size: 24pt;
}

.menu-ativo::after {
	content: "";
	position: absolute;
	height: 2px;
	background: var(--bg-primario);
	top: 98%;
	width: 70%;
	left: 50%;
	transform: translateX(-50%);
}

.menu-ativo,
.menu-ativo i {
	color: var(--bg-primario);
}

.menu-ativo{
	background: #ecebebc5;
	padding: 2px;
}
.menu-item:hover,
.menu-item:hover i {
	transition: all 0.5s;
	color: #000;
}

.menu-item:hover::after {
	content: "";
	position: absolute;
	height: 2px;
	background: #000;
	top: 98%;
	width: 70%;
	left: 50%;
	transform: translateX(-50%);
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
	opacity: 1;
}

.menu-ativo:hover,
.menu-ativo:hover i {
	color: var(--bg-primario);
}

.menu-ativo:hover::after {
	content: "";
	position: absolute;
	height: 2px;
	background: var(--bg-primario);
	top: 98%;
	width: 70%;
	left: 50%;
	transform: translateX(-50%);
}

@media screen and (max-width: 768px) {

	#MenuLateral {
		top: 5px;
		left: 0px;
		right: 0px;
		bottom: unset;
		height: 50px;
		position: relative;
		width: 100%;
		display: flex;
		padding: 0px 10px;
		overflow-x: auto;
		z-index: 2;
	}

	#MenuLateral {
		display: flex;
		flex-direction: row !important;
	}

	.menu-scroll {
		flex-direction: row;
	}

	.menu-item {
		width: 100%;
		padding: 0px;
		margin: 0px;
		height: 95%;
	}

	.menu-item span {
		width: 150px;
		font-size: 8pt;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.menu-item i {
		font-size: 14pt;
	}
}
</style>
