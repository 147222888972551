import {FormaPagamento} from "../models/FormaPagamento.js";
import {doRequest} from "./http.js";
export default {
	state: {
		formasPagamento: [],
		paginaFormasPagamentoAtual: 0,
		totalPaginasFormasPagamento: -1,
		FormasPagamento: {},
		filtrarFormasPagamento: [],
		formasPgtoVenda: []
	},
	actions: {
		async buscarFormasPagamentoVenda({commit, state}, payload) {
			state.carregando = true;
			try {
				let url;
				if (!payload || payload == undefined){
					url = "formaPagamento/vendas?page=0&size=25&sort=descricao&sortOrder=asc&somenteAtivo=A";
				}
				doRequest(url, {method: "get", cache: "no-cache"}, commit).then((res) => {
					if (!res.content) {
						throw new Error(res.mensagem || (res.details ? JSON.stringify(res.details) : JSON.stringify(res)));
					}
					commit("atualizarListaFormasPagamentoVenda", res);
					if (payload ? !payload.carregando : true) {
						state.carregando = false;
					}
				}).catch(() => {
					state.carregando = false;
					commit("atualizarListaFormasPagamentoVenda", { content: [], page: -1, totalPages: 0});					
				});
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("atualizarListaFormasPagamentoVenda", { content: [], page: -1, totalPages: 0});
			}
		},


		async buscarFormasPagamento({commit, state}, payload){
			state.carregando = true;
			try {
				let url;
				if (!payload || payload == undefined){
					url = "formaPagamento/all?page=0&size=25&sort=descricao&sortOrder=asc&somenteAtivo=A";
				} else {
					url = `formaPagamento/all?page=${payload.page || 0}&size=25&sort=descricao&sortOrder=asc`;
					if(payload.checkboxPesquisa){
						url += "&somenteAtivo=A";
					}
				}
				doRequest(url, {method: "get", cache: "no-cache"}, commit).then((res) => {
					if (!res.content) {
						throw new Error(res.mensagem || (res.details ? JSON.stringify(res.details) : JSON.stringify(res)));
					}
					commit("atualizarListaFormasPagamento", res);
					if (payload ? !payload.carregando : true) {
						state.carregando = false;
					}
				}).catch(() => {
					state.carregando = false;
					commit("atualizarListaFormasPagamento", { content: [], page: -1, totalPages: 0});					
				});
			} catch (error) {
				state.carregando = false;
				console.log(error);
				commit("atualizarListaFormasPagamento", { content: [], page: -1, totalPages: 0});
			}
		},
		async buscarFormasPagamentoPorId({commit, state}, cdtipo){
			state.carregando = true;
			try {
				const url = `formaPagamento?id=${cdtipo}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				state.carregando = false;
				if (!data.cdtipo) {
					return null;
				}
				return {...data};
			} catch (error) {
				state.carregando = false;
				console.log(error);
				return null;
			}
		},
		async filtrarFormasPagamento({commit}, payload){
			try{
				//state.carregando = true;
				const {
					query,
					page,
					checkboxPesquisa,
				} = payload;
                
				let url;
				if (!payload || payload == undefined){
					url = `formaPagamento/filter?page=${page || 0}&query=${query}&size=25&sort=descricao&sortOrder=asc&somenteAtivo=A`;
				} else {
					url = `formaPagamento/filter?page=${page || 0}&query=${query}&size=25&sort=descricao&sortOrder=asc`;
					if(checkboxPesquisa){
						url += "&somenteAtivo=A";
					}
				}
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if (data.content && data.content.length > 0){
					commit("atualizarListaFormasPagamento", data);
					//state.carregando = false;
					return data;
				} else {
					return false;
				}
			}catch (error){
				console.log(error);
				commit("atualizarListaFormasPagamento", { content: [], page: -1, totalPages: 0});
				commit("relatarErro", {mensagem: error.mensagem || (error.mensagem || JSON.stringify(error))});               
				return {erro: true};
			}
		},
		async verificaFormaPagamentoPorDescricao({commit}, payload) {
			try {
				const url = `formaPagamento/descricao?descricao=${`${payload}`}`;
				const data = await doRequest(url, {method: "get", cache: "no-cache"}, commit);
				if(data.length>0) return data;
				return false;
			}catch(error) {
				return false;
			}
		},
		async cadastrarFormasPagamento({commit, state, dispatch}, payload) {
			try {
				state.carregando = true;
				const formaCadastro = new FormaPagamento(payload);
				const cadastrado = await dispatch("verificaFormaPagamentoPorDescricao", formaCadastro.descricao);
				if(cadastrado) { 
					throw new Error("Existe forma de pagamento com a mesma descrição. Verifique!");
				}
				const body = JSON.stringify(formaCadastro);
				let url = "formaPagamento";
				const data = await doRequest(url, {method: "post", body}, commit);
				if(formaCadastro.planoContasFixo[0].cdplano !== "SEL"){
					const planoContasVenda = JSON.stringify(formaCadastro.planoContasFixo[0]);
					await doRequest(url+"/adiciona-plano", {method: "post", body:planoContasVenda}, commit);
				}
				if(formaCadastro.planoContasFixo[1].cdplano !== "SEL"){
					const planoContasCompra = JSON.stringify(formaCadastro.planoContasFixo[1]);
					await doRequest(url+"/adiciona-plano", {method: "post", body:planoContasCompra}, commit);
				}
				state.carregando = false;
				if (data.message == "Server Error") {
					return commit("relatarErro", {mensagem: JSON.stringify(data.details)});
				}else if (data.cdtipo){
					return commit("relatarErro", {
						mensagem: `Forma de pagamento de código ${data.cdtipo} cadastrada com sucesso`,
						sucess: true,
					});
				}
			}catch (error) {
				return commit("relatarErro", {
					mensagem: error,
				});
			}
		},
		async atualizarFormasPagamento({commit, state, dispatch}, payload) {
			try {
				state.carregando = true;
				const formaAlteracao = new FormaPagamento(payload);
				const cadastrado = await dispatch("verificaFormaPagamentoPorDescricao", formaAlteracao.descricao);
				if(cadastrado) { 
					if(cadastrado.length === 1 && cadastrado[0].cdtipo !== payload.cdtipo){
						throw new Error("Existe forma de pagamento com a mesma descrição. Verifique!");
					}
				}
				formaAlteracao.planoContasFixo = formaAlteracao.planoContasFixo.filter(obj => obj.cdplano !== "SEL");
				const body = JSON.stringify(formaAlteracao);
				let url = `formaPagamento?id=${payload.cdtipo}`;
				const data = await doRequest(url, {body,method: "put", cache: "no-cache"}, commit);
				state.carregando = false;
				if (data.message == "Server Error") {
					return commit("relatarErro", {mensagem: JSON.stringify(data.details)});
				}
				for(const plano of formaAlteracao.planoContasFixo){
					const planoContasVenda = JSON.stringify(plano);
					if(!plano.id){
						await doRequest("formaPagamento/adiciona-plano", {method: "post", body:planoContasVenda}, commit);
					}else{
						await doRequest(`formaPagamento/altera-plano?id=${plano.id}`, {method: "put", body:planoContasVenda}, commit);
					}
				}
			} catch (error) {
				return commit("relatarErro", {
					mensagem: error,
				});
			}
		},
	},
	mutations: {
		atualizarListaFormasPagamentoVenda(state, payload) {
			state.formasPgtoVenda = [];
			state.formasPgtoVenda = [...payload.content];
			state.paginaFormasPagamentoAtual = payload.page;
			state.totalPaginasFormasPagamento = payload.totalPages;
		},

		atualizarListaFormasPagamento(state, payload){
			state.formasPagamento = [];
			state.formasPagamento = [...payload.content];
			state.paginaFormasPagamentoAtual = payload.page;
			state.totalPaginasFormasPagamento = payload.totalPages;
		},
	}
};