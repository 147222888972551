<template>
	<div id="grid-relatorio">
		<!-- <i class="fas fa-eject" v-if="$store.state.Usuario.supervisor" @click="abrirRelatorioMenu"></i> -->
		<MenuLateral tipo="Relatorio" v-on:consultarDashboard="mudarMenu('Dashboard')"
			v-on:consultarComissao="mudarMenu('Comissao')"
			v-on:consultarCaixas="mudarMenu('Caixas')" v-on:consultarContas="mudarMenu('Contas')"
			v-on:consultarProdutos="mudarMenu('Produtos')" v-on:consultarLucroProduto="mudarMenu('LucroProduto')"
			v-on:consultarEstoque="mudarMenu('Estoque')" v-on:consultarEntrada="mudarMenu('Entrada')"
			v-on:consultarFornecedor="mudarMenu('Fornecedor')" 
			v-on:consultarHistoricoProduto="mudarMenu('HistoricoProduto')" 
			v-on:consultarLotesVencimento="mudarMenu('LotesVencimento')"
			v-on:consultarPosicaoEstoque="mudarMenu('PosicaoEstoque')"
			v-on:consultarFaturamentoDiasXHoras="mudarMenu('FaturamentoDiasXHoras')"
			v-on:consultarAniversarios="mudarMenu('Aniversarios')"
		/>

		<div id="relatorio-conteudo">
			<Dashboard v-if="mostrar === 'Dashboard'" />

			<RelatorioVendedor v-else-if="mostrar == 'Comissao'" />

			<RelatorioCaixas v-else-if="mostrar == 'Caixas'" />

			<RelatorioContasReceber v-else-if="mostrar == 'Contas'" />

			<RelatorioProdutosVendidos v-else-if="mostrar == 'Produtos'" />

			<RelatorioDesempenhoProduto v-else-if="mostrar == 'LucroProduto'" />

			<RelatorioSaldoEstoque v-else-if="mostrar == 'Estoque'" />

			<RelatorioEntradaProduto v-else-if="mostrar == 'Entrada'" />

			<RelatorioFornecedor v-else-if="mostrar === 'Fornecedor'" />

			<RelatorioHistoricoProduto v-else-if="mostrar === 'HistoricoProduto'" />

			<RelatorioLotesVencimento v-else-if="mostrar === 'LotesVencimento'" />

			<RelatorioFaturamentoDiasXHoras v-else-if="mostrar === 'FaturamentoDiasXHoras'" />

			<RelatorioPosicaoEstoque v-else-if="mostrar === 'PosicaoEstoque'" />

			<RelatorioAniversarios v-else-if="mostrar === 'Aniversarios'"/>
		</div>
	</div>
</template>

<script>
import MenuLateral from "../components/MenuLateral.vue";
import Dashboard from "../components/Dashboard.vue";
import RelatorioVendedor from "../components/RelatorioComissao.vue";
import RelatorioCaixas from "../components/RelatorioCaixas.vue";
import RelatorioContasReceber from "../components/RelatorioContasReceber.vue";
import RelatorioProdutosVendidos from "../components/RelatorioProdutosVendidos.vue";
import RelatorioDesempenhoProduto from "../components/RelatorioDesempenhoProduto.vue";
import RelatorioSaldoEstoque from "../components/RelatorioSaldoEstoque.vue";
import RelatorioEntradaProduto from "../components/RelatorioEntradaProduto.vue";
import RelatorioFornecedor from "../components/RelatorioFornecedor.vue";
import RelatorioHistoricoProduto from "../components/RelatorioHistoricoProduto.vue";
import RelatorioLotesVencimento from  "../components/RelatorioLotesVencimento.vue";
import RelatorioPosicaoEstoque from  "../components/RelatorioPosicaoEstoque.vue";
import RelatorioFaturamentoDiasXHoras from "../components/RelatorioFaturamentoDiasXHoras.vue";
import RelatorioAniversarios from "../components/RelatorioAniversarios.vue";

export default {
	name: "Relatorios",

	components: {
		MenuLateral,
		Dashboard,
		RelatorioVendedor,
		RelatorioCaixas,
		RelatorioContasReceber,
		RelatorioProdutosVendidos,
		RelatorioDesempenhoProduto,
		RelatorioSaldoEstoque,
		RelatorioEntradaProduto,
		RelatorioFornecedor,
		RelatorioHistoricoProduto,
		RelatorioLotesVencimento,
		RelatorioPosicaoEstoque,
		RelatorioFaturamentoDiasXHoras,
		RelatorioAniversarios,
	},

	data() {
		return {
			mostrar: "Comissao",
		};
	},

	methods: {
		mudarMenu(menu) {
			this.mostrar = menu;
		},
		verificarMenuInQuery() {
			if (this.$route.query) {
				const { menu } = this.$route.query;
				if (menu) {
					this.mostrar = menu;
				}
			}
		}
	},

	mounted() {
		this.verificarMenuInQuery();
	},
};
</script>

<style scoped>
#grid-relatorio {
	width: 100%;
	height: auto;
	max-height: 90vh;
	display: grid;
	grid-template-columns: 120px 1fr;
	grid-template-rows: 1fr;
	grid-template-areas:
		"menu conteudo";
}

#MenuLateral {
	grid-area: menu;
}

#relatorio-conteudo {
	grid-area: conteudo;
	height: auto;
	max-height: 90vh;
}

.conteudo-item {
	width: 100%;
	height: 100%;
	overflow-y: auto;
}

#grid-vendedor {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto 1fr;
	grid-template-areas:
		"info-conteudo"
		"filtro-vendedores"
		"sub-item";
}

.informacoes-conteudo {
	display: flex;
	flex-direction: row;
	grid-area: info-conteudo;
	align-items: center;
	justify-content: space-between;
	padding: 0px 10px;
}

.informacoes-item {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.informacoes-item label {
	display: flex;
	justify-content: center;
	align-items: center;
}

.informacoes-item input[type="date"] {
	max-width: 140px;
}

#dataI {
	margin-right: 5px;
}

#filtro-vendedores {
	display: flex;
	flex-direction: row;
	overflow-x: auto;
	padding: 8px 0px;
	max-width: 98vw;
	grid-area: filtro-vendedores;
}

.vendedor-item {
	padding: 5px;
	margin: 0px 5px;
	cursor: pointer;
}

.vendedor-ativo {
	background-color: var(--bg-primario);
	box-shadow: var(--shadow-primario);
	color: #fff;
	border-radius: 5px;
	cursor: default;
}

.conteudo-subitem {
	border-radius: 10px;
	box-shadow: var(--shadow-secundario);
	overflow-y: auto;
	height: 100%;
	grid-area: sub-item;
	position: relative;
}

.fas.fa-print {
	padding: 5px;
	border-radius: 100%;
	box-shadow: var(--shadow-primario);
	color: var(--bg-primario);
	background-color: var(--bg-app);
	font-size: 28pt;
	position: fixed;
	bottom: 15px;
	right: 15px;
	z-index: 2;
}

.relatorio-todos {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr;
	grid-template-areas:
		"tabela-vendedores tabela-vendedores"
		"grafico-bruto grafico-comissao";
}

.grafico-bruto {
	grid-area: grafico-bruto;
	width: 400px;
	margin: 0px auto;
}

.grafico-comissao {
	grid-area: grafico-comissao;
	width: 400px;
	margin: 0px auto;
}

table {
	grid-area: tabela-vendedores;
	background: #ddd;
	width: 100%;
	cursor: default;
}

td,
th {
	padding: 5px;
	cursor: default;
}

td:nth-child(1),
th:nth-child(1) {
	text-align: center;
}

td:nth-child(2),
th:nth-child(2) {
	text-align: left;
}

td:nth-child(3),
th:nth-child(3),
td:nth-child(4),
th:nth-child(4),
td:nth-child(5),
th:nth-child(5),
td:nth-child(6),
th:nth-child(6),
td:nth-child(7),
th:nth-child(7) {
	text-align: right;
}

#grid-vendedor-relatorio {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr;
	grid-template-areas:
		"vendedor-relatorio vendedor-relatorio"
		"grafico-vendas relatorio-comissao";
}

.vendedor-relatorio {
	grid-area: vendedor-relatorio;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-around;
}

.vendedor-relatorio-item {
	display: flex;
	flex-direction: column;
	height: 160px;
	width: 160px;
	box-shadow: var(--shadow-primario);
	margin: 10px;
	border-radius: 10px;
	justify-content: space-around;
	align-items: center;
	cursor: default;
}

.vendedor-relatorio-item span:nth-child(2) {
	background-color: var(--bg-primario);
	color: #fff;
	padding: 10px;
	width: 60px;
	height: 60px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.vendedor-relatorio-item span:nth-child(3) {
	font-size: 14pt;
	color: var(--bg-primario);
}

.grafico-vendas {
	display: flex;
	justify-content: center;
	grid-area: grafico-vendas;
	box-shadow: var(--shadow-primario);
	margin: 5px;
	border-radius: 10px;
	max-height: 380px;
	position: relative;
}

.grafico-vendas i {
	color: var(--bg-primario);
	font-size: 28pt;
	position: absolute;
	top: 0;
	right: 0;
}

.vendedor-relatorio-comissao {
	grid-area: relatorio-comissao;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 10px;
}

.vendedor-relatorio-comissao-item {
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 10px;
	height: 48%;
	box-shadow: var(--shadow-primario);
}

.vendedor-relatorio-comissao-item h4 {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90%;
	margin: 0px auto;
	color: var(--bg-primario);
	border-bottom: 1px solid var(--bg-primario);
}

.vendedor-relatorio-comissao-item div {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	height: 100%;
}

.vendedor-relatorio-comissao-item div span {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

@media screen and (max-width: 1125px) {
	.informacoes-conteudo {
		flex-direction: column;
		align-items: flex-start;
	}

	.informacoes-item {
		max-width: 100%;
		width: auto;
		justify-content: flex-start;
	}

	.informacoes-item input[type="date"] {
		max-width: 150px;
	}

	.vendedor-relatorio-comissao-item div {
		flex-direction: column;
		padding: 5px;
	}

	.vendedor-relatorio-comissao-item div span {
		flex-direction: row;
		justify-content: center;
		width: 100%;
		text-align: right;
	}

	.vendedor-relatorio-comissao-item div span span:nth-child(1) {
		width: 60%;
		display: flex;
		justify-content: flex-end;
		text-align: right;
		margin: 0px 2px;
	}

	.vendedor-relatorio-comissao-item div span span:nth-child(2) {
		width: 40%;
		display: flex;
		justify-content: flex-start;
		text-align: right;
		margin: 0px 2px;
	}
}

@media screen and (max-width: 864px) {
	#relatorio-menu i {
		font-size: 18pt;
	}

	#relatorio-menu span {
		font-size: 11pt;
	}

	#relatorio-conteudo {
		font-size: 11pt;
	}

	.vendedor-relatorio-comissao-item div {
		justify-content: flex-start;
	}
}

@media screen and (max-width: 768px) {
	#grid-relatorio {
		width: 100%;
		height: auto;
		max-height: 90vh;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 100px 1fr;
		grid-template-areas:
			"menu"
			"conteudo";
	}

	#MenuLateral {
		top: 0vh;
		left: 0px;
		right: 0px;
		bottom: unset;
		height: 90px;
		position: relative;
		width: 100%;
		display: flex;
		padding: 0px 10px;
		overflow-x: auto;
		z-index: 2;
	}

	#MenuLateral {
		display: flex;
		flex-direction: row !important;
	}

	#grid-vendedor-relatorio {
		width: 100%;
		display: grid;
		max-height: 77.5vh;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto auto 1fr;
		grid-template-areas:
			"vendedor-relatorio vendedor-relatorio"
			"relatorio-comissao relatorio-comissao"
			"grafico-vendas grafico-vendas";
	}

	.vendedor-relatorio-comissao {
		flex-direction: row;
	}

	.vendedor-relatorio-comissao-item {
		height: auto;
		width: 48%;
	}

	.vendedor-relatorio-item {
		width: 120px;
	}
}

@media screen and (max-width: 695px) {

	.informacoes-conteudo .informacoes-item:nth-child(1) {
		display: none;
	}

	.vendedor-relatorio {
		flex-wrap: wrap;
	}

	.vendedor-relatorio-comissao {
		flex-direction: column;
	}

	.vendedor-relatorio-comissao-item {
		height: auto;
		width: 100%;
		margin-bottom: 10px;
	}

	.vendedor-relatorio-comissao-item div {
		flex-direction: row;
		padding: 5px;
	}

	.vendedor-relatorio-comissao-item div span {
		flex-direction: column;
		justify-content: center;
		width: 100%;
		text-align: right;
	}

	.vendedor-relatorio-comissao-item div span span:nth-child(1) {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		text-align: right;
		margin: 0px 2px;
	}

	.vendedor-relatorio-comissao-item div span span:nth-child(2) {
		width: 40%;
		display: flex;
		justify-content: flex-start;
		text-align: right;
		margin: 0px 2px;
	}
}

@media screen and (max-width: 600px) {
	.fas.fa-eject {
		display: block;
	}

	#relatorio-menu {
		background-color: var(--bg-app);
		box-shadow: 0px 5px 5px #292929;
		position: fixed;
		visibility: hidden;
		opacity: 0;
		animation-name: fadeOutOpacity;
		animation-iteration-count: 1;
		animation-timing-function: ease-in;
		transition: 1s;
		padding-top: 30px;
	}

	#relatorio-menu::after {
		content: '';
		position: absolute;
		top: 0;
		left: 98px;
		width: 100vw;
		height: 92vh;
		background-color: rgba(0, 0, 0, 0.7);
		z-index: 5;
		animation-name: fadeInOpacity;
		animation-iteration-count: 1;
		animation-timing-function: ease-in;
		animation-duration: 0.5s;
		opacity: 1;
	}

	#relatorio-menu.open-menu {
		visibility: visible !important;
		transition: 1s;
		animation-name: fadeInOpacity;
		animation-iteration-count: 1;
		animation-timing-function: ease-in;
		opacity: 1;
	}

	#grid-relatorio {
		grid-template-columns: 1fr;
	}

	.informacoes-conteudo {
		align-items: flex-end;
	}
}

@media screen and (max-width: 520px) {
	.informacoes-conteudo {
		align-items: center;
	}

	.informacoes-item {
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	.informacoes-item div {
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.informacoes-item div input[type="date"] {
		width: 120px;
	}

	.informacoes-item div input[type="date"]::-webkit-calendar-picker-indicator {
		display: none;
	}
}
</style>