<template>
	<nav class="navBar" @mouseleave="alterarConfiguracoes('navBar')">
		<div class="container">
			<i v-if="!mostrarMenu" @click="abrirMenu" class="fas fa-bars js-iconeMenu"></i>
			<i v-else @click="abrirMenu" class="fas fa-times js-iconeMenu"></i>

			<div class="identificar-empresa">
				<i class="fas fa-chevron-left icones visivel mx-5 text-black" v-if="!mostrarVoltar" @click="handleControlePagina"></i>
				<div @click="resetarLocalStorage">
					<router-link class="logo mx-5" to="/" @click.prevent="">
						<img id="navImg"
							:src="Empresa ? Empresa.logo ? `data:image/png;base64,${Empresa.logo}` : require('../assets/logo-transp-tidoc.png') : require('../assets/logo-transp-tidoc.png')"
							alt="LogoBDS">
					</router-link>
				</div>
				<div class="col w-100 mx-10" v-if="Empresa">
					<span class="w-100 empresa-atual" style="align-items: flex-end;" v-if="Empresa">
						<span class="desktop text-black">{{ Empresa.id + " - " + Empresa.nome }}</span>
						<span class="tablet text-black">{{ Empresa.id + " - " + (Empresa.nome.length > 30 ? (Empresa.nome ||
								"").substring(0, 30) + "..." : Empresa.nome)
						}}</span>
						<span class="smartphone text-black">{{ Empresa.id + " - " + (Empresa.nome.length > 7 ? (Empresa.nome ||
								"").substring(0, 7) + "..." : Empresa.nome)
						}}</span>
						<i class="fas fa-sign-out-alt visivel mx-5 text-black" @click="mudarEmpresa"
							v-if="usuario && !usuario.empresa"
							style="font-size: 8pt; align-self: flex-end; padding-bottom: 4px;"></i>
					</span>
					<div class="row w-100 h-100" v-if="Empresa">
						<span class="empresa-atual desktop text-black">{{ agora }}</span>
						<span class="empresa-atual tablet text-black">{{ agora }}</span>
						<span class="empresa-atual smartphone text-black">{{ agora.substring(0, 16) }}</span>
						<i class="fas fa-sync visivel pointer text-black" v-if="Empresa"
							@click="$store.dispatch('buscarStateInicial', { atualizar: true })"></i>
					</div>
					<div class="row w-100 h-100" v-if="$store.state.Caixa">
						<span class="empresa-atual desktop">
							{{ $store.state.Caixa.caixa.cdcaixa }} - {{ $store.state.Caixa.caixa.identificador }}
						</span>
						<span class="empresa-atual tablet">
							{{ $store.state.Caixa.caixa.cdcaixa }} - {{ $store.state.Caixa.caixa.identificador }}
						</span>
						<span class="empresa-atual smartphone">
							{{ $store.state.Caixa.caixa.cdcaixa }} - {{$store.state.Caixa.caixa.identificador.substring(0, 16)}}
						</span>
						<i class="fas fa-sign-out-alt visivel mx-5 pointer"
							style="font-size: 8pt; align-self: flex-end; padding-bottom: 4px;"
							@click="$router.replace({ name: 'SelecionaCaixa' })"
							v-if="(usuario.supervisor || usuario.caixa) && $store.state.Caixas"></i>
					</div>
				</div>
			</div>

			<div v-if="usuario" id="usuario-controle" class="mx-10"
				@click="alterarConfiguracoes(timerConfiguracao == null, 'usuario')">
				<i class="fas fa-user icones visivel"></i>
				<span class="usuario-nome">{{ `${usuario.nome}`.split(" ")[0] }}</span>
			</div>
			<ul v-if="usuario" class="links">
				<i class="fas fa-cog icones visivel mx-10" @click="alterarConfiguracoes(true, 'rotas')"></i>
				<i class="fas fa-sign-out-alt icones visivel mx-10" @click="logOut"></i>
			</ul>

			<div class="configuracoes" v-if="usuario" @mouseleave="alterarConfiguracoes(false)"
				@mouseenter="alterarConfiguracoes(true, mostrarConfiguracoes)">
				<ul @click="alterarConfiguracoes(false)">
					<li @click="alterarConfiguracoes(false)" class="configuracoes-item"
						v-for="(link, index) in links.filter(link => link.nome != paginaAtual)" :key="index">
						<router-link :to="{name: link.nome, query: link.query || {}}">{{ link.texto }}</router-link>
					</li>
				</ul>
			</div>

		</div>
		<ul v-if="usuario" class="vlinks">
			<li @click="abrirMenu" class="configuracoes-item"
				v-for="(link, index) in links.filter(link => link.nome != paginaAtual)" :key="index">
				<router-link :to="{name: link.nome, query: link.query || {}}">{{ link.texto }}</router-link>
			</li>
			<li @click="abrirMenu" class="configuracoes-item">
				<a @click="logOut">Sair</a>
			</li>
			<span class="versao-vlinks">v.{{ $store.state.versao }}</span>
		</ul>
		<span class="versao">v.{{ $store.state.versao }}</span>
	</nav>
</template>
<script>
import { localStorageReset } from "../service/localStorage";


export default {
	name: "NavBar",

	props: ["texto"],

	computed: {
		paginaAtual() {
			return this.$route.name;
		},

		usuario() {
			return this.$store.state.Usuario;
		},

		Empresa() {
			return this.$store.state.Empresa;
		},

		mostrarVoltar() {
			return this.rotasVoltar.includes(this.$route.name);
		},
		links() {
			const links = [];
			if (this.usuario) {
				if (this.usuario.caixa) {
					const rotas = this.rotasCaixa.filter(rota => rota.condicao ? this.usuario[rota.condicao] : true);
					links.push(...rotas);
				}
				if(this.usuario && this.usuario?.usuarioPrivilegiado) {
					links.push({
						...this.rotasComum[0],
						query: {
							menu: "Dashboard"
						}
					});
					links.push({
						...this.rotasComum[1],
					});
				} else {
					links.push(...this.rotasComum);
				}
				if (this.usuario.supervisor) {
					if (!this.usuario.caixa) {
						links.push(...this.rotasCaixa.filter(rota => rota.condicao ? this.usuario[rota.condicao] : true));
					}
					const rotas = this.rotasSupervisor.filter(rota => rota.condicao ? this.usuario[rota.condicao] : true);
					links.push(...rotas);
				}
				if (this.usuario.usuarioPrivilegiado) {
					if (!this.usuario.caixa) {
						links.push(...this.rotasCaixa.filter(rota => rota.condicao ? this.usuario[rota.condicao] : true));
					}
					if (!this.usuario.supervisor) {
						links.push(...this.rotasSupervisor.filter(rota => rota.condicao ? this.usuario[rota.condicao] : true));
					}
					const rotas = this.rotasUsuarioPrivilegiado.filter(rota => rota.condicao ? this.usuario[rota.condicao] : true);
					links.push(...rotas);
				}
				const rotas = this.rotasVendedor.filter(rota => rota.condicao ? this.usuario[rota.condicao] : true);
				links.push(...rotas);
			}
			return links;
		}
	},

	data() {
		return {
			mostrarMenu: false,
			mostrarConfiguracoes: false,
			rotasVoltar: [
				"Principal",
				"Lancamento",
				"TrocarSenha",
				"Autenticacao",
				"Impressao",
			],
			rotasUsuarioPrivilegiado: [
				{
					nome: "Acessos",
					texto: "Acessos",
					condicao: "incluiUsuario"
				},
				{
					nome:"Logs",
					texto: "Logs",
					condicao: "incluiUsuario"
				}
			],
			rotasSupervisor: [
				{
					nome: "Empresas",
					texto: "Empresas",
					condicao: "incluiEmpresa"
				},
			],
			rotasCaixa: [				
				{
					nome: "Gerenciamento",
					query: {
						menu: "Clientes"
					},
					texto: "Gerenciamento",
				},
				{
					nome: "Vendas",
					texto: "Vendas"
				},
			],
			rotasComum: [				
				{
					nome: "Relatorios",
					query: {
						menu: "Comissao"
					},
					texto: "Relatorios"
				},
				{
					nome: "Financeiro",
					query: {
						menu: "ContasPagar"
					},
					texto: "Financeiro"
				},
			],
			rotasVendedor: [
				{	
					nome: "FecharCaixa",
					texto: "Consultar caixa",
					condicao: "consultarCaixa"
				},
				{
					nome: "TrocarSenha",
					texto: "Trocar senha"
				},
				{
					nome: "Vendas",
					texto: "Vendas"
				},
			],
			timerConfiguracao: null,
			agora: new Date().toLocaleString("pt-BR"),
			atualizarHora: null
		};
	},

	methods: {
		handleControlePagina() {
			if(!this.usuario.usaCelular && this.Empresa.tiposistema === "L") {
				this.$router.push({name: "LancamentoLanchonete"});
			} else if (this.usuario.usaCelular) {
				this.$router.push({name: "Principal"});
			} else {
				this.$router.go(- 1);
			}
		},
		resetarLocalStorage(){
			localStorageReset();
		},
		async mudarEmpresa() {
			if (!this.usuario.empresa) {
				await this.$store.dispatch("buscarEmpresas");
				this.$router.replace({ name: "SelecionaEmpresa" });
				this.$store.state.Empresa = null;
			}
		},
		atualizarAgora() {
			this.agora = new Date().toLocaleString("pt-BR");
			if (this.atualizarHora) {
				clearTimeout(this.atualizarHora);
			}
			this.atualizarHora = setTimeout(this.atualizarAgora, 1000);
		},

		abrirMenu() {
			this.mostrarMenu = document.querySelector(".vlinks") ? !document.querySelector(".vlinks").classList.contains("open") : false;
			document.querySelector(".vlinks").classList.toggle("open");
		},

		alterarConfiguracoes(abrir, tipo) {
			if (abrir == "navBar") {
				clearTimeout(this.timerConfiguracao);
				return this.timerConfiguracao = setTimeout(() => {
					document.querySelector(".configuracoes")?.classList.remove("open");
					this.timerConfiguracao = null;
				}, 350);
			}
			if (abrir) {
				if (!tipo && !this.timerConfiguracao) {
					return document.querySelector(".configuracoes").classList.remove("open");
				}
				document.querySelector(".configuracoes").classList.add("open");
				this.mostrarConfiguracoes = tipo;
				clearTimeout(this.timerConfiguracao);
			} else {
				clearTimeout(this.timerConfiguracao);
				this.timerConfiguracao = setTimeout(() => {
					document.querySelector(".configuracoes").classList.remove("open");
					this.timerConfiguracao = null;
				}, 200);
			}
		},

		logOut() {
			this.$store.dispatch("logOut");
		},

		inicio() {
			if (this.$route.name != "Principal") {
				this.$router.push({ name: "Principal" });
			}
		},
	},
	mounted() {
		this.atualizarAgora();
	},
	destroyed() {
		if (this.atualizarHora) {
			clearTimeout(this.atualizarHora);
		}
	}
};

</script>

<style scoped>
.text-black{
	color: #000;
	font-weight: bold;
}

.navBar {
	width: 100%;
	/*background: var(--bg-primario);*/
	background-color: #FFF;
	position: fixed;
	height: 100%;
	/*box-shadow: 0px 1px 2px 3px rgba(0, 0, 0, 0.6);*/
	/*border-bottom: 1px solid #000;*/
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
	z-index: 5;
}

.container {
	padding: 0px 50px;
	width: 100%;
	margin: 0px;
	display: flex;
	height: 100%;
	background-color: #dcdcdc;
	/*background: var(--bg-primario);*/
}

.links {
	list-style: none;
	display: flex;
	justify-content: flex-end;
}

.logo {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.logo #navImg {
	border-radius: 10px;
	width: auto;
	object-fit: cover;
	max-height: 75px;
}

.configuracoes {
	top: -100vh;
	position: absolute;
	right: 1%;
	width: 260px;
	background: #FFF;
	z-index: -1;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	transition-timing-function: ease-in-out;
	transition-duration: 300ms;
	transition-timing-function: all;
	box-shadow: 0px 6px 6px 2px rgba(0, 0, 0, 0.6);
}

.configuracoes.open {
	transform: translateY(110vh);
	transition-timing-function: ease-in-out;
	transition-delay: 0ms;
	transition-duration: 300ms;
	transition-timing-function: all;
}

.configuracoes-item {
	width: 100%;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	transition-timing-function: ease-in-out;
	transition-duration: 300ms;
	transition-timing-function: all;
}

.configuracoes-item:hover {
	cursor: pointer;
}

.configuracoes-item a {
	width: 100%;
	height: 35px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: bolder;
	font-size: 18px;
	color: #000;
	padding: 15px;
	transition-timing-function: ease-in-out;
	transition-duration: 300ms;
	transition-timing-function: all;
}

.configuracoes-item:hover a {
	background: #dfdfdf;
	border-radius: 8px;
}

.configuracoes-item a i {
	transition-timing-function: ease-in-out;
	transition-duration: 300ms;
	transition-timing-function: all;
}

.configuracoes-item:hover a i {
	color: var(--primario);
}

#usuario-controle {
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

#usuario-controle i {
	padding: 7px;
	border-radius: 25%;
	box-shadow: 0px 0px 2px #161616;
	background-color: rgba(180, 180, 180, 0.7);
}

.usuario-nome {
	color: #000;
	font-weight: 700;
	margin: auto;
}

.btn-configuracoes {
	color: var(--branco);
	margin: 0 5px;
	background: var(--bg-primario);
	padding: 10px;
	border-radius: 10px;
	font-weight: bolder;
	font-size: 18px;
	cursor: pointer;
	text-align: center;
	align-items: center;
	justify-content: center;
}

.btn-configuracoes:hover {
	color: var(--primario);
	background: var(--bg-app);
}

.vlinks {
	display: none;
}

i {
	display: none;
}

.visivel {
	display: flex;
	align-self: center;
	font-size: 24px;
	color: #000;
}
.identificar-empresa {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-grow: 1;
}
.empresa-atual {
	height: 100%;
	text-align: left;
	display: flex;
	align-items: center;
	color: #000;
	font-weight: bold;
	font-size: 8pt;
	cursor: default;
}

.fas.fa-sync {
	font-size: 8pt;
}

.tablet {
	display: none;
}

.smartphone {
	display: none;
}

.versao {
	color: #000;
	font-weight: 700;
	font-size: 7pt;
	position: absolute;
	right: 5px;
	bottom: 0px;
	user-select: none;
	cursor: default;
}

.versao-vlinks {
	color: #000;
	font-weight: 700;
	font-size: 12pt;
	user-select: none;
	cursor: default;
	position: fixed;
	right: 8px;
	bottom: 8px;
	display: none;
}

.vlinks.open .versao-vlinks {
	display: block;
}


@media screen and (max-width: 768px) {
	.links {
		display: none;
	}

	#usuario-controle {
		display: none;
	}

	i {
		display: flex;
		align-items: center;
		font-size: 24px;
	}

	.container {
		padding: 0px 20px;
		flex-direction: row-reverse;
		justify-content: space-between;
		flex-wrap: nowrap;
		overflow: hidden;
	}

	.vlinks {
		margin-top: 9vh;
		display: block;
		position: fixed;
		left: -100vw;
		height: 100%;
		width: 100vw;
		/* background: var(--bg-primario); */
		transition: all 1s;
		background-color: #dcdcdc;
		z-index: 5;
	}

	.js-iconeMenu{
		color: #000;
	}

	.vlinks.open {
		transform: translateX(100vw);
	}

	#navImg {
		max-width: 100px;
	}

	.configuracoes {
		display: none !important;
	}

	.desktop {
		display: none;
	}

	.tablet {
		display: flex;
	}

	.versao {
		display: none;
	}
}

@media screen and (max-width: 560px) {
	.container {
		padding: 0px 10px;
	}

	.tablet {
		display: none;
	}

	.smartphone {
		display: flex;
	}
}

@media screen and (max-height: 560px) {
	.vlinks {
		justify-content: center;
		overflow: auto;
	}

	.container {
		margin: 0px auto;
	}
}
</style>