export class classificacaoAPI {
	bloqueiavendaprazo;
	descprazo;
	descvista;
	limitecondicional;
	descricao;

	constructor(classificacao){
		this.bloqueiavendaprazo = classificacao.somenteVista;
		this.descprazo = typeof(classificacao.descValorPrazo) == "number" ? Number(Number(classificacao.descValorPrazo).toFixed(4)) : Number(`${classificacao.descValorPrazo || 0}`.split(".").join("").replace(",", "."));
		this.descvista = typeof(classificacao.descValorVista) == "number" ? Number(Number(classificacao.descValorVista).toFixed(4)) : Number(`${classificacao.descValorVista || 0}`.split(".").join("").replace(",", "."));
		this.limitecondicional = typeof(classificacao.qtdProdutos) == "number" ? Number(Number(classificacao.qtdProdutos)) : Number(`${classificacao.qtdProdutos || 0}`);
		this.descricao = classificacao.nomeClassficacao.toUpperCase();
		this.padrao = classificacao.padrao ? classificacao.padrao : false;
	}
}

export class Classificacao{
	cdClassificacao; 
	nomeClassficacao;
	somenteVista;
	descValorVista;
	descValorPrazo;
	qtdProdutos;
	constructor(classificacao){
		this.cdClassificacao = classificacao.cdclassi;
		this.nomeClassficacao = classificacao.descricao;
		this.somenteVista = classificacao.bloqueiavendaprazo;
		this.descValorPrazo = classificacao.descprazo;
		this.descValorVista = classificacao.descvista;
		this.qtdProdutos = classificacao.limitecondicional;
	}
}

export class CreditoClienteAPI{
	cdcliente;
	cdIdentificador;
	cdempresa;
	cdvendedor;
	uciduser;
	dataCadastro;
	valor;
	observacao;
	qtdeParcela;
	utilizado;
	constructor({
		cdcliente,
		cdIdentificador,
		cdempresa,
		cdvendedor,
		uciduser,
		dataCadastro,
		valor,
		observacao,
		qtdeParcela,
		utilizado,
	}){
		this.cdcliente = cdcliente;
		this.cdIdentificador = cdIdentificador;
		this.cdempresa = cdempresa;
		this.cdvendedor = cdvendedor;
		this.uciduser = uciduser;
		this.dataCadastro = dataCadastro;
		this.valor = valor;
		this.observacao = observacao;
		this.qtdeParcela = qtdeParcela;
		this.utilizado = utilizado;
	}
}

export class CreditoCliente {
	id;
	cdempresa;
	observacao;
	status;
	valor;
	valorPago;
	cliente;
	vendedor;
	utilizado;
	data;
	constructor(credito){
		this.id = credito.cdconsorcio;
		this.cdempresa = credito.cdempresa;
		this.observacao = credito.observacao;
		this.status = credito.status;
		this.valor = credito.valor;
		this.valorPago = credito.valorPago;
		this.cliente = {
			id: credito.cliente.id,
			nome: credito.cliente.nome,
			cnpj: credito.cliente.cnpj
		};
		this.vendedor = {
			id: credito.vendedor.id,
			nome: credito.vendedor.nome
		};
		this.utilizado = credito.utilizado;
		this.data = credito.data;
	}
}