<template>
	<div class="container-modal-plano">
		<div class="modal-titulo">
			<span>
				Adicionar Plano Conta
			</span>
		</div>
		<div class="container-formulario">
			<div class="container-input">
				<div class="input-group">
					<select 
						id="planoContaPai"
						v-model="idContaPai"
						@change="handleSelecionaContaPai(idContaPai)"
					>
						<option disabled value="SEL">
							SELECIONE UMA CONTA PAI
						</option>
						<option
							v-for="pai in PlanoContasSelecionar" 
							:key="pai.vid"
							:value="pai.vid"
						>
							{{ pai.nome }}
						</option>
						<option :value="null">
							CONTA SEM CONTA PAI
						</option>
					</select>
					<label for="planoContaPai">
						Conta pai
					</label>
				</div>
				<div class="input-group">
					<input 
						type="text" 
						id="nomeConta"
						autocomplete="off"
						v-model="nome"
						placeholder=" "
					>
					<label for="nomeConta">
						Descrição
					</label>
				</div>
				<div class="input-group-switch">
					<label class="label-switch">
						Altera valor
					</label>
					<label class="container">
						<input 
							type="checkbox" 
							class="esconder-input"
							id="esconder-input"
							v-model="alterarValor"
						/>
						<span class="thumb-container">
							<span class="thumb"></span>
						</span>
							<span class="track"></span>
					</label>
				</div>
			</div>
			<div class="container-input">
				<div class="input-group">
					<select 
						id="tipoPlanoConta" 
						v-model="tipoPlanoConta"
					>
						<option 
							disabled 
							value="SEL"
						>
							SELECIONE UMA OPÇÃO
						</option>
						<option 
							value="POSITIVO"
						>
							A RECEBER
						</option>
						<option 
							value="NEGATIVO"
						>
							A PAGAR
						</option>
						<option 
							value="AMBOS"
						>
							AMBOS
						</option>
					</select>
					<label 
						for="tipoPlanoConta"
					>
						Tipo Conta
					</label>
				</div>
				<div class="input-group" id="input-previsao" v-if="alterarValor === true">
					<input 
						type="text" 
						id="valorPrevisao"
						autocomplete="off"
						v-model="valorPrevisao"
						v-mascaraFloat
						placeholder=" "
					>
					<label for="valorPrevisao">
						$ Previsão
					</label>
				</div>
			</div>
		</div>
		<div class="botoes">
			<button 
				class="botao botao-acao" 
				@click="fecharModal"
			>
				<i 
					class="fas fa-times" 
					style="color: var(--borda-cancelar)" 
				/>
				<span>
					Fechar
				</span>
			</button>
			<button 
				class="botao botao-adicionar"
				@click="adicionarPlanoConta"
			>
				<i 
					class="fas fa-check"
					style="color: var(--borda-adicionar)"
				/>
				<span v-if="!EditandoConta">
					Adicionar
				</span>
				<span v-else>
					Salvar
				</span>
			</button>
		</div>
	</div>
</template>
<script>
export default{
	name: "AdicionarPlanoContas",
	props: {
		PlanoContasProp: Object,
		desabilitaTipoConta: Boolean,
		EditandoConta: Boolean,
	},
	data(){
		return {
			nome: "",
			idContaPai: "SEL",
			tipoPlanoConta: "SEL",
			alterarValor: false,
			idConta: null,
			sequencia: null,
			valorPrevisao: null,
		};
	},
	computed: {
		PlanoContasVisualizacao() {
			return this.$store.state.PlanoContas?.ListaPlanoContasVisualizacao || [];
		},

		PlanoContasSelecionar() {
			return this.PlanoContasVisualizacao && this.PlanoContasVisualizacao.length > 0 ?
			this.PlanoContasVisualizacao?.filter(pc => pc.alterarValor !== 1) : [];
		},


		PlanoContas() {
			return this.idConta 
				? this.$store.state?.PlanoContas?.ListaPlanoContas?.filter(pc => pc.cod !== this.idConta) 
				: this.$store.state?.PlanoContas?.ListaPlanoContas;
		},
	},
	mounted(){
		this.buscarPlanoContasView();
		this.buscarPlanoContas();
		this.disableInputTipoConta(this.desabilitaTipoConta);
		this.preencherPlanoConta(this.PlanoContasProp);
	},

	methods: {
		handleSelecionaContaPai(idContaPai) {
			if(idContaPai !== null) {
				const contaPai = this.PlanoContas.find(p => p.cod === idContaPai);
				const tipoContaPai = contaPai?.tipoConta;
				this.tipoPlanoConta = tipoContaPai;
				this.disableInputTipoConta(true);
			} else {
				this.tipoPlanoConta = "SEL";
				this.disableInputTipoConta(false);
			}
		},
		
		//CONSULTAS
		async buscarPlanoContasView(){
			await this.$store.dispatch("buscarPlanoContasVisualizar");
			this.$forceUpdate();
		},

		async buscarPlanoContas() {
			await this.$store.dispatch("buscarPlanoContas", {
				somentePai: false,
			});
		},

		//MODAL,
		preencherPlanoConta(conta) {
			this.idConta = conta.cod;
			this.nome = conta.descricao;
			this.idContaPai = conta.idContaPai || "SEL";
			this.tipoPlanoConta = conta.tipoConta || "SEL";
			this.alterarValor = conta.alteraValor;
			this.sequencia = conta.sequencia;
			this.valorPrevisao = Number(conta.valorPrevisao || 0).toLocaleString("pt-BR", {
				style: "decimal",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			});
		},

		async fecharModal(){			
			this.limparFormulario();
			await this.buscarPlanoContasView();
			this.$emit("fecharModal");
		},

		disableInputTipoConta(disabled) {
			const selectContaPai = document.getElementById("tipoPlanoConta");
			selectContaPai.disabled = disabled;
		},

		limparFormulario(){
			this.nome = "";
			this.idConta = "";
			this.idContaPai = "SEL";
			this.tipoPlanoConta = "SEL";
			this.alterarValor = false;
		},

		validarCampos(){
			try {
				if(!this.nome){
					throw new Error("Por favor digite uma descrição adequada");
				} else if(this.tipoPlanoConta === null || this.tipoPlanoConta === "SEL"){
					throw new Error("Por favor selecione um tipo da conta");
				}
				return true;
			} catch (error) {
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
				return false;
			}
		},

		async adicionarPlanoConta(){
			const form = {
				id: this.idConta ? this.idConta : null,
				nome: this.nome ? this.nome : null,
				idContaPai: this.idContaPai != "SEL" ? this.idContaPai : null,
				tipoPlanoConta: this.tipoPlanoConta != "SEL" ? this.tipoPlanoConta : null,
				alterarValor: this.alterarValor ? this.alterarValor : false,
				sequencia: this.sequencia,
				previsao: Number(`${this.valorPrevisao || 0}`.replace(".", "").replace(",", ".")),
			};
			if(!this.validarCampos()) return;
			if(this.idConta){
				await this.$store.dispatch("editarPlanoConta", {...form});
				this.fecharModal();
				return;
			}
			const res = await this.$store.dispatch("adicionarPlanoConta", {...form});
			if(!res?.mensagem || !res?.erro){
				this.$store.commit("relatarErro", {
					mensagem: "Plano de Contas cadastrado com sucesso!",
					sucess: true
				});
			}
			this.fecharModal();
		},

		async buscarContaPorId(id) {
			try {
				const data = await this.$store.dispatch("buscarPlanoContaPorId", {id});
				if(data) {
					return data;
				}
				throw new Error("Alguma coisa deu errado, por favor contate o suporte!");
			} catch (error) {
				console.log(error);
				this.$store.commit("relatarErro", {
					mensagem: error.message
				});
			}
		}
	}
};
</script>
<style scoped>
#nomeConta{
	width: 35vw;
}
#input-previsao{
	width: 260px;
}
.track{
	width: 70px;
}
.botoes{
	width: 100%;
	display: flex;
	margin-top: 10px;
	flex-direction: row;
	gap: 10px;
}
.botoes button{
	width: 50%;
}
.modal-titulo{
	font-size: 24px;
	text-align: left;
	padding: 5px 5px;
}
.container-formulario{
	width: 100%;
	padding: 10px;
	border: solid 2px #cecece;
	height: 22vh;
	display: flex;
	flex-direction: column;	
}
.esconder-input:checked + .thumb-container{
	transform: translateX(150%);
}
.container {
	width: 50%;
	height: 67vh;
	overflow-y: auto;
}
.container h2{
	text-align: center;
}
.container-input{
	display: flex;
	flex-direction: row;
	padding: 5px;
	width: 100%;
}
.container-input .input-group select{
	width: 250px;
}
.container-input .input-group input{
	width: 600px;
}
.row-filtro .container-input .input-group:focus-within label,
.row-filtro .container-input .input-group input:not(:placeholder-shown) ~ label {
	padding: 0px 4px;
	background-color: #f8f8f8;
	top: -12px;
	left: 16px;
	bottom: auto;
	color: var(--text);
}
.row-filtro .container-input .input-group:focus-within label,
.row-filtro .container-input .input-group select:not(:placeholder-shown) ~ label {
	padding: 0px 4px;
	background-color: #f8f8f8;
	top: -12px;
	left: 16px;
	bottom: auto;
	color: var(--text);
}
.input-group-switch{
	margin-top: 5px;
}
.label-switch {
	white-space: nowrap;
}
@media screen and (max-width: 425px) {
	.container-input .input-group select{
		width: 100%;
	}
	.container-input .input-group{
		width: 100%;
		margin-top: 10px;
	}
	.container-input {
		flex-wrap: wrap;
	}
}
</style>