<template>
    <div class="box-funcionalidades">
        <div class="funcionalidade-atual"  v-for="opcao in Object.keys(parametroCadastrar.opcoes)" :key="opcao">
			<label class="mensagem-opcao" :for="opcao">{{parametroCadastrar.opcoes[opcao].mensagem}}</label>
            <div class="checked">
                <div class="selecionar">
                    <input v-model="parametroCadastrar.opcoes[opcao].valor" :value="true" :id="opcao" @change="passarDados()" type="checkbox"/>
                    <label :for="opcao" class="check"></label>
                    <span v-if="parametroCadastrar.opcoes[opcao].valor">&nbsp;SIM</span>
                    <span v-else>&nbsp;NÃO</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default{
	name:"ParametrosUsuarios",
	props:{
		Parametro: Object,
	},
	data(){
		return {
			parametroCadastrar: {
				opcoes: {
					"usuarioPrivilegiado": {
						mensagem: "Usuário com mais altos privilégios",
						valor: false
					},
					"supervisor": {
						mensagem: "Usuário supervisor",
						valor: false
					},
					"caixa": {
						mensagem: "Usuário caixa",
						valor: false
					},
					"permiteDescontoAvista":{
						mensagem: "Desconto no pedido a vista",
						valor: false
					},
					"descontoRecebimento":{
						mensagem: "Desconto no recebimento",
						valor: false
					},
					"liberaDesconto":{
						mensagem: "Liberar desconto",
						valor: false
					},
					"controlaCliente":{
						mensagem: "Validar dados do cliente",
						valor: false
					},
					"incluiCliente":{
						mensagem: "Incluir cliente",
						valor: false
					},
					"incluiVendedor":{
						mensagem: "Incluir vendedor",
						valor: false
					},
					"incluiProduto":{
						mensagem: "Incluir produtos, grupos e promoções",
						valor: false
					},
					"incluiConta":{
						mensagem: "Incluir contas, caixas e bandeiras",
						valor: false
					},
					"incluiCompra":{
						mensagem: "Incluir compras",
						valor: false
					},
					"incluiFormaPagamento":{
						mensagem: "Incluir formas de pagamento",
						valor: false
					},
					"incluiEmpresa":{
						mensagem: "Incluir empresas",
						valor: false
					},
					"incluiUsuario":{
						mensagem: "Incluir usuários",
						valor: false
					},
					"liberaCliente":{
						mensagem: "Liberar cliente",
						valor: false
					},
					"permiteVenderSaldoNegativo":{
						mensagem: "Lançar produto sem saldo",
						valor: false
					},
					"alteraEstoque":{
						mensagem: "Altera estoque do produto",
						valor: false
					},
					"permiteAlterarVenda":{
						mensagem: "Alterar venda dentro do tempo",
						valor: false
					},
					"caixaConsultaVendas": {
						mensagem: "Consultar vendas completo",
						valor: false
					},
					"permiteBaixarCondicional":{
						mensagem: "Visualizar e baixar condicionais",
						valor: false
					},
					"vendeParaOutroVendedor":{
						mensagem: "Trocar vendedor do pedido",
						valor: false
					},
					"solicitaVendedorNoPedido": {
						mensagem: "Solicitar vendedor ao iniciar pedido",
						valor: false
					},
					"mostrarNoPedido": {
						mensagem: "Mostrar no pedido",
						valor: false
					},
					"vendedorRecebePrazo":{
						mensagem: "Lançar financeiro do pedido",
						valor: false
					},
					"somenteVendaSemCaixa":{
						mensagem: "Sempre aprovar vendas no caixa",
						valor: false
					},
					"multiTroca": {
						mensagem: "Trocar produtos sem venda",
						valor: false
					},
					"condicionalConsumidorFinal":{
						mensagem: "Condicional para o consumidor final",
						valor: false
					},
					"solicitaNomeConsumidorFinal":{
						mensagem: "Nao validar dados do consumidor final",
						valor: false
					},
					"validarCnpj":{
						mensagem: "Validar CPF/CNPJ do cupom",
						valor: false
					},
					"controlaCartao": {
						mensagem: "Validar dados do cartão",
						valor: false
					},
					"usaCelular": {
						mensagem: "Lançamento pelo celular",
						valor: false
					},		
					"verTodasVendas": {
						mensagem: "Ver todas as vendas comissão",
						valor: false
					},
					"consultarCaixa":{
						mensagem: "Consultar Caixa",
						valor: false
					}		
				},
			},
		};
	},
	methods: {
		preencherParametros(parametros) {
			if (!parametros) return;
			Object.keys(parametros).forEach(key => {
				if (!this.parametroCadastrar.opcoes[key]) {
					console.log("Falta a key", key);
				} else {
					this.parametroCadastrar.opcoes[key].valor = parametros[key] == "S";
				}
			});
			this.passarDados();
		},
		passarDados(){			
			Object.keys(this.parametroCadastrar.opcoes).forEach(key => {
				this.parametroCadastrar[key] = this.parametroCadastrar.opcoes[key].valor;
			});
			this.$emit("passarDados", this.parametroCadastrar);
		},
	},
	mounted(){
		this.$bus.$on("preencherParametros", this.preencherParametros);
		if(this.Parametro != null){
			this.preencherParametros(this.Parametro);
		}
	},

	destroyed() {
		this.$bus.$off("preencherParametros", this.preencherParametros);
	}
};
</script>