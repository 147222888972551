import { render, staticRenderFns } from "./ConsultaCidades.vue?vue&type=template&id=60c36a31&scoped=true&"
import script from "./ConsultaCidades.vue?vue&type=script&lang=js&"
export * from "./ConsultaCidades.vue?vue&type=script&lang=js&"
import style0 from "./ConsultaCidades.vue?vue&type=style&index=0&id=60c36a31&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60c36a31",
  null
  
)

export default component.exports