<template>
	<div class="main between">
		<div class="row between w-100">
			<div class="col center m-2 w-50">
				<div class="row between">
					<i class="fas fa-external-link-alt" @click="$emit('consultarProdutos')"
						v-if="$store.state.inserirProduto"></i>
					<label for="js-id-produto">Código do produto</label>
					<i
						class="fas fa-barcode"
						@click="abrirLeitorCodigoBarras"
						style="color: #000"
						v-if="podeLerCodigo"
					></i>
				</div>
				<input
					type="text"
					v-model="idItem"
					id="js-id-produto"
					min="0"
					class="inputControle sm"
					@keydown.up.prevent=""
					@keydown.down.prevent=""
					@keyup.enter="(!$store.state.consultandoProduto && produtoSelecionado && produtoSelecionado != 'naoencontrado') || produtoSelecionado && produtoSelecionado !== 'naoencontrado' && baixarCondicional ? proximoInput('js-quant') : null"
					@input="verificarInput"
					autocomplete="off"
					:placeholder="$store.state.inserirProduto ? '(F4) Consulta Produtos' : 'Devolver produto'"
				/>
			</div>
			<div class="col center m-2 w-50">
				<label for="js-quant">
					Quantidade
				</label>
				<input
					type="text"
					v-model="quantidade"
					id="js-quant"
					min="1"
					class="inputControle sm"
					@input="verificarInput"
					@keyup.enter="
						!produtoSelecionado || produtoSelecionado == 'naoencontrado' 
						? proximoInput('js-id-produto') : adicionarProduto()
					"
					autocomplete="off"
				/>
			</div>
		</div>

		<ItemProduto
			class="descricao-produto"
			:item="produtoSelecionado"
		/>

		<div class="row between w-100">
			<button
				class="botao botao-acao w-100 m-5"
				id="btn-consorcio"
				@click="$emit('iniciarConsorcio')"
				v-if="!$route.query.baixarCondicional && !$route.query.trocarProdutos"
			>
				Consórcio
			</button>
			<button
				class="botao botao-acao w-100 m-5"
				@click="solicitaInserirDevolver"
				id="btn-baixa"
				v-if="($route.query.baixarCondicional && !$route.query.alteracao)"
				style="width: 100%;"
			>
				{{ baixarCondicional ? "Adicionar mais itens" : "Devolver itens" }}
			</button>
			<button
				class="botao botao-cancelar w-100 m-5"
				id="js-btn-cancelar"
				@click="cancelarEdicao"
			>
				Cancelar edição
			</button>
			<button
				class="botao botao-acao w-100 m-5"
				id="tipoVenda"
				@click="alterarTipoVenda"
				v-if="!$route.query.baixarCondicional && !$route.query.trocarProdutos && !ehTroca"
			>
				{{ $route.query.tipoVenda === "S" ? "Condicional" : "Venda" }}
			</button>
			<button
				class="botao botao-acao w-100 m-5"
				id="tipoVenda"
				@click="$emit('adicionarTroca')"
				v-if="$route.query.trocarProdutos"
			>
				Adicionar Troca
			</button>
		</div>

		<div class="row between w-100">
			<button
				class="botao botao-cancelar w-100 m-5 mb-10 "
				@click="cancelarVenda"
			>
				Cancelar (Alt+c)
			</button>
			<button
				class="botao botao-adicionar w-100 m-5 mb-10 "
				v-if="salvarPreVenda"
				@click="finalizarVenda"
			>
				Pré-venda (Alt+s)
			</button>
			<button class="botao botao-adicionar w-100 m-5 mb-10 " v-else @click="finalizarVenda">Finalizar (Alt+s)</button>
		</div>

		<Modal v-if="leitor" v-on:fecharModal="fecharLeitor">
			<div id="barcode-scanner-container">
				<button class="botao botao-cancelar" id="btn-cancelar-barcode-scanner"
					@click="fecharLeitor">Cancelar</button>
				<div id="barcode-scanner">
					<video src=""></video>
					<canvas class="drawingBuffer" height="auto" width="100%"></canvas>
				</div>
			</div>
		</Modal>
		<Modal
			v-if="produtosRepetidos"
			:dimensoes="{width: 'auto', height: 'auto'}"
			:idModal="'modal-mesmo-codbarra'"
			@fecharModal="cancelarCodBarraRepetido"
		>
			<div class="containerModalProdutosRepetido">
				<div
					class="containerMsgProdRepetido"
				>
					<P>
						<i class="fas fa-info-circle" />
						Produto com código de barras repetido, por favor selecione algum produto, clicando na linha da tabela!
					</P>

				</div>
				<div
					class="containerTabelaFilhos"
				>
					<table class="tabelaFilhos" id="tabelaFilhos">
						<thead>
							<tr>
								<th style="color: blue;">Cód Produto</th>
								<th>Descrição</th>
								<th>Saldo</th>
								<th>Valor à vista</th>
								<th>Valor à prazo</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(produto, index) in  ProdutosMesmoCodBarra"
								:key="index"
								@click="adicionarCodBarraRepetido(produto)"
							>
								<td style="color: blue;">
									{{ produto.id }}
								</td>
								<td>
									{{ produto.descricao }}
								</td>	
								<td>
									{{ produto.saldo }}
								</td>
								<td>
									{{ 
										(produto.vlrvista || 0).toLocaleString("pt-BR", {
											style: "currency",
											currency: "BRL"
										}) 
									}}
								</td>
								<td>
									{{
										(produto.pontoequi || 0).toLocaleString("pt-BR", {
											style: "currency",
											currency: "BRL"
										})
									}}
								</td>
							</tr>
						</tbody>
					</table>
					<button
						class="botao botao-cancelar"
						@click="cancelarCodBarraRepetido"
					>
						Cancelar
					</button>
				</div>
			</div>
		</Modal>
		<Modal
			v-if="modalFilhos"
			:dimensoes="{width: 'auto', height: 'auto'}"
			:idModal="'prodPai'"
		>
			<div class="containerMensagem">
				<p>Você não pode adicionar um produto pai a venda, selecione um produto filho abaixo</p>
			</div>
			<div
				v-if="produtosFilhos"
				class="containerTabelaFilhos"
			>
				<table class="tabelaFilhos" id="tabelaFilhos">
					<thead>
						<tr>
							<th style="color: blue;">Cód Produto</th>
							<th>Descrição</th>
							<th>Saldo</th>
							<th>Valor à vista</th>
							<th>Valor à prazo</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(produto, index) in  produtosFilhos"
							:key="index"
							@click="adicionarFilho(produto)"
						>
							
							<td style="color: blue;">
								<button
									id="buttonSeta"
									class="handle-button"
									type="button"
									style="background: transparent; outline: none; border: none;"
								>
									<i
										class="fas fa-hand-point-right"
										style="color: var(--primario)"
										v-if="index == filhoSelecionadoIndex">
									</i>
								</button>
								{{ produto.id }}
							</td>
							<td>
								{{ produto.descricao }}
							</td>	
							<td>
								{{ produto.saldo }}
							</td>
							<td>
								{{ 
									(produto.vlrvista || 0).toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL"
									}) 
								}}
							</td>
							<td>
								{{
									(produto.pontoequi || 0).toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL"
									})
								}}
							</td>
						</tr>
					</tbody>
				</table>
				<button
					class="botao botao-cancelar"
					@click="cancelarSelecaoFilhos"
				>
					Cancelar
				</button>
			</div>
		</Modal>
	</div>
</template>

<script>
import ItemProduto from "./ItemProduto.vue";
import Quagga from "quagga/dist/quagga.js";
import Modal from "../components/Modal.vue";
export default {
	name: "InserirProdutos",

	components: {
		Modal,
		ItemProduto
	},

	props: ["itens", "produtos"],

	data() {
		return {
			idItem: "",
			quantidade: "1",
			valorUnitario: "",
			cdTipo: "S",
			baixarCondicional: false,
			timer: null,
			podeLerCodigo: false,
			produtoBalanca: false,
			modalFilhos: false,
			filhoSelecionadoIndex: 0,
			timerVerificaInput: null,
			lockBuscarProduto: false,
			produtosRepetidos: false,
		};
	},

	computed: {
		ehTroca(){
			return !!this.$store.state.itensVenda.find((p)=> p.cdTipo=="T");
		},

		leitor() {
			return this.$store.state.leitor;
		},

		produtoSelecionado() {
			if(this.$store.state.produtoSelecionado == "naoencontrado"){
				const input = document.getElementById("js-id-produto");
				input.focus();
			}
			return this.$store.state.produtoSelecionado;
		},

		salvarPreVenda() {
			return (!this.$store.state.Usuario.supervisor && !this.$store.state.Usuario.caixa) ? (this.$store.state.Venda.valorVista && !this.$store.state.Usuario.somenteVendaSemCaixa) : false;
		},
		produtosFilhos(){
			return this.$store.state.produtoFilhos;
		},
		Lotes(){
			return this.$store.state.Lotes;
		},
		ProdutosMesmoCodBarra() {
			return this.$store.state.ProdutosCodBarraRepetido;
		}
	},

	methods: {
		
		cancelarCodBarraRepetido() {
			this.produtosRepetidos = false;
			this.$store.state.ProdutosCodBarraRepetido = [];
		},

		async adicionarCodBarraRepetido(item) {
			this.produtosRepetidos = false;
			this.$store.state.ProdutosCodBarraRepetido = [];
			const produto = await this.$store.dispatch("selecionarProdutoPorEmpresa", {
				id: item.id,
				cdempresa: item.cdEmpresa || "ALL"
			});
			this.emitirEventos({...produto, quantiade: "1"});
		},
		selecionarFilhoSeta(e){
			const table = document.getElementById("tabelaFilhos");
			table?.scrollBy({
				top: e.key == "ArrowUp" ? -30 : 30
			});
			if (e.key == "ArrowUp") {
				e.preventDefault();
				if (this.filhoSelecionadoIndex != 0) {
					this.filhoSelecionadoIndex--;
					this.$forceUpdate();
				} else {
					this.filhoSelecionadoIndex = this.produtosFilhos.length;
				}
			}
			if (e.key == "ArrowDown") {
				e.preventDefault();
				if (this.filhoSelecionadoIndex < this.produtosFilhos.length -1) {
					this.filhoSelecionadoIndex++;
					this.$forceUpdate();
				} else {
					this.filhoSelecionadoIndex = 0;
				}
			}
			if(this.modalFilhos){
				e.preventDefault();
				if(e.key == "Enter"){
				
					this.adicionarFilho(this.produtosFilhos[this.filhoSelecionadoIndex]);
				}
			}
		},

		cancelarSelecaoFilhos(){
			this.modalFilhos = false;
			this.idItem = "";
			this.$store.commit("selecionarProduto", null);
		},
		async adicionarFilho(filho){
			this.modalFilhos = false;
			this.$store.state.produtoFilhos = [];
			const produto = await this.$store.dispatch("selecionarProdutoPorEmpresa", {
				id: filho.id,
				cdempresa: filho.cdEmpresa || "ALL"
			});
			this.emitirEventos({...produto, quantiade: "1"});
		},

		verificarInput(e) {
			const id = e.target.id;
			const value = e.target.value;
			const regex = /[0-9.,]/;
			if(this.lockBuscarProduto) return;
			if((e.target.value.length > 12 || e.target.value.length === 12) && e.target.value.charAt(0) != "2" && id != "js-quant"){
				this.lockBuscarProduto = true;
				this.adicionarProduto().then(()=>{
					if(this.$store.state.produtoSelecionado != "naoencontrado"){
						this.proximoInput("js-id-produto");
						this.idItem = "";
					}
					this.lockBuscarProduto = false;
				});
				return;
			}
			if(e.target.value.length > 12 && e.target.value.charAt(0) == "2" && id != "js-quant"){
				return this.buscarProdutoBalanca({
					cdbarra: value,
					tamanho: e.target.value.length
				});
			}
			if ((!regex.test(e.data) && e.data != null) || (e.data == "." || e.data == ",")) {
				if (id == "js-id-produto" && e.data == ",") {
					this.idItem = value.substring(0, value.length - 1);
				} else if (id == "js-quant") {
					this.quantidade = value.substring(0, value.length - 1) + (e.data == "." ? "," : e.data == "," ? "," : "");
				}
				return;
			}
			if (id == "js-id-produto") {
				this.$store.commit("handleConsultandoProduto", true);
				clearTimeout(this.timerVerificaInput);
				this.timerVerificaInput = setTimeout(() => {
					if((e.target.value.length > 0 && e.target.value.length < 12)) {
						this.iniciarTimer();
					}
				}, 125);
			}
		},

		async buscarProdutoBalanca({cdbarra}){
			const idProdBalanca = cdbarra.slice(4, 7);
			const peso = cdbarra.slice(8, 12);
			this.idItem = idProdBalanca;			
			this.$store.state.inserirProduto = true;
			
			this.quantidade = this.verificaPeso(peso).replace(".", ",");
			this.produtoBalanca = true;
			this.adicionarProduto();

		},
		verificaPeso(peso){
			if(peso.length === 3){
				const novoPeso = parseFloat(peso) / 1000;
				return `${novoPeso}`.toLocaleString("pt-BR", {
					style: "decimal",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				});
			} else if (peso.length === 4){
				if(peso.charAt(0) === "1"){
					const novoPeso = parseFloat(`1.${peso.substring(1)}`) + 0.0;
					return `${novoPeso}`.toLocaleString("pt-BR", {
						style: "decimal",
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				} else {
					const novoPeso = parseFloat(peso) / 1000;
					return `${novoPeso}`.toLocaleString("pt-BR", {
						style: "decimal",
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					});
				}
			} else {
				return null;
			}
		},
		iniciarTimer() {
			clearTimeout(this.timer);
			this.timer = setTimeout(async () => {
				await this.selecionarItem();
			}, 500);
		},

		async selecionarItem() {
			let produto;
			produto = await this.$store.dispatch("buscarProdutoPorCodigoBarras", `${this.idItem}`);
			if(produto === "produtosrepetidos") return this.produtosRepetidos = true;
			if (!produto) {
				produto = this.$emit("buscarItemPorId", {
					id: this.idItem,
					devolucao: this.baixarCondicional,
					quantidade: null
				});
			}
			else{
				this.emitirEventos({
					...produto,
					quantidade: this.quantidade,
					ehPeso: false
				});
			}
			this.$store.commit("handleConsultandoProduto", true);
			return produto;
		},


		async adicionarProduto() {
			if (this.idItem == 0 && this.idItem != "") {
				return this.finalizarVenda();
			}
			let produto;
			if (this.$store.state.inserirProduto) {
				if(this.produtoSelecionado && this.produtoSelecionado != "naoencontrado" && this.produtoSelecionado?.ativo != undefined ){
					produto = this.produtoSelecionado;
				}
				else{
					if(!this.$store.state.editarProduto) {
						produto = await this.$store.dispatch("buscarProdutoPorCodigoBarras", `${this.idItem}`);
						if(produto === "produtosrepetidos") return this.produtosRepetidos = true;
					}
					if (!produto) {
						produto = await this.$store.dispatch("buscarProdutoPorId", this.idItem);
					}
					if(!produto){
						await this.$store.dispatch("buscarFilhos", {pai: this.idItem});
						if(this.produtosFilhos.length > 0){
							this.modalFilhos = true;
							return;
						}
					}
				}
				if (!produto) {
					return this.$store.commit("relatarErro", { mensagem: "Produto não encontrado" });
				}
				if(produto?.embalagem !== "KG" && this.produtoBalanca) {
					this.quantidade = Number(this.quantidade.replace(",",""));
				}
				await this.$store.dispatch("buscarFilhos", {pai: produto.id, empresa: produto.cdEmpresa});
				if(this.produtosFilhos.length > 0){
					this.modalFilhos = true;
					return;
				}
				await this.$store.dispatch("buscarLotes", {cdProduto: produto.id, empresa: this.$store.state.Empresa.id, saldo:"S"});
				if(!this.$store.state.editarProduto )this.$emit("verificaLotes",{...produto, quantidade: this.quantidade, ehPeso: this.produtoBalanca});
				if(this.Lotes.length>0 && !this.$store.state.editarProduto ){
					return;
				}
				this.$store.commit("selecionarProduto", {...produto});
			} else {
				this.$emit("buscarItemPorId", {
					id: this.idItem,
					devolucao: this.baixarCondicional,
					focar: true,
					quantidade: this.quantidade
				});
				setTimeout(() => {
					this.quantidade = "1";
					this.idItem = "";
					if(this.baixarCondicional) {
						document.getElementById("js-id-produto").focus();
					}
				}, 250);

				return this.$store.commit("selecionarProduto", null);
			}
			if (this.produtoSelecionado == "naoencontrado") {
				return this.$store.commit("relatarErro", { mensagem: "Produto não encontrado" });
			}
			if (this.produtoSelecionado == "cadastroincorreto") {
				return this.$store.commit("relatarErro", { mensagem: "Produto com cadastro incorreto, Verifique!" });
			}
			if (typeof (this.quantidade) == "string") {
				this.quantidade = Number(this.quantidade.replace(".", "").replace(",", "."));
			}
			if (this.quantidade < 0) {
				this.quantidade = 1;
			}
			this.emitirEventos({...produto, quantidade: this.quantidade, ehPeso: this.produtoBalanca});
			this.produtoBalanca = false;

		},
		emitirEventos(produto) {
			if (this.$store.state.editarProduto) {
				// if (!this.$store.state.Venda.liberacao?.cliente.liberado && this.$store.state.Venda.tipoVenda == "C" ){
				// this.cancelarEdicao();
				// }
				if (!this.$store.state.Usuario.permiteVenderSaldoNegativo && (Number(this.quantidade) > this.produtoSelecionado.saldo)) {
					return setTimeout(() => {
						document.getElementById("js-id-produto").readOnly = false;
						document.getElementById("js-id-produto").disabled = false;
						document.getElementById("js-btn-cancelar").style.display = "none";
						document.getElementById("tipoVenda") ? document.getElementById("tipoVenda").style.display = "block" : null;
						document.getElementById("js-quant").value = 1;
						document.getElementById("js-id-produto").value = "";
						this.quantidade = 1;
						this.idItem = "";
						this.$store.commit("relatarErro", { mensagem: "A quantidade informada é maior que a que está em estoque, favor verificar." });
					}, 50);
				}
				this.$emit("atualizarItem", { novoItem: { quantidade: Number(produto.quantidade || 1), valorUnitario: Number(this.valorUnitario), descricao: produto.descricao, ehPeso: produto.ehPeso } });
			} else if (this.$store.state.inserirProduto) {
				this.$emit("adicionarItem", {
					...produto,
					ehPeso: produto.ehPeso,
					quantidade: Number(produto.quantidade) || 1,
					valorUnitario: this.$store.state.Venda?.valorVista ?
						Number(!Number(this.valorUnitario) ? Number((produto.promocaoVista && !this.$route.query.trocarProdutos) ? (produto?.valorPromocaoVista) : produto.valorVista) : Number(this.valorUnitario)) :
						Number(!Number(this.valorUnitario) ? Number((produto.promocaoPrazo && this.$route.query.tipoVenda != "C" && !this.$route.query.trocarProdutos) ? (produto.valorPromocaoPrazo) : produto.valorPrazo) : Number(this.valorUnitario)),
				});
			} else if (!this.$store.state.inserirProduto) {
				const btnCancelar = document.getElementById("js-btn-cancelar");
				if (btnCancelar) {
					btnCancelar.style.display = "none";
				}

				let produtos = this.$store.state.itensVenda
					.filter(item => `${item.produto.id}` == `${this.produtoSelecionado?.produto?.id}` && item.situacao != "D");
				let quant = 0;
				if (produtos.length > 1) {
					quant = produtos.map(prod => prod.quantidade).reduce((t, v) => t += v);
				} else if (produtos.length == 1) {
					quant = produtos[0].quantidade;
				} else {
					this.$store.state.produtoSelecionado = null;
					return this.$store.commit("relatarErro", {
						mensagem: "Não há mais deste produto a ser devolvido, Verifique!"
					});
				}
				if (this.quantidade > quant) {
					this.$store.state.produtoSelecionado = null;
					return this.$store.commit("relatarErro", {
						mensagem: "Você está devolvendo uma quantidade maior que a retirada da loja"
					});
				}
				produtos.forEach(prod => {
					if (this.quantidade <= 0) return;
					if (this.quantidade >= prod.quantidade) {
						this.$store.state.itensVenda[this.$store.state.itensVenda.map(item => item.seq).indexOf(prod.seq)].situacao = "D";
					} else {
						const existeDevolucao = this.$store.state.itensVenda.find(item => item.produto.id == prod.produto.id && item.situacao == "D");
						if (!existeDevolucao) {
							this.$store.commit("inserirItemVenda", {
								...this.$store.state.itensVenda[this.$store.state.itensVenda.map(item => item.seq).indexOf(prod.seq)].produto,
								quantidade: this.quantidade,
								situacao: "D"
							});
						} else {
							this.$store.state.itensVenda[this.$store.state.itensVenda.map(item => item.seq).indexOf(existeDevolucao.seq)].quantidade = Number(this.$store.state.itensVenda[this.$store.state.itensVenda.map(item => item.seq).indexOf(existeDevolucao.seq)].quantidade) + Number(this.quantidade);
						}
						this.$store.state.itensVenda[this.$store.state.itensVenda.map(item => item.seq).indexOf(prod.seq)].quantidade = Number(this.$store.state.itensVenda[this.$store.state.itensVenda.map(item => item.seq).indexOf(prod.seq)].quantidade) - Number(this.quantidade);
					}
					this.quantidade -= prod.quantidade;
				});

				document.getElementById("js-id-produto").focus();
			}

			this.quantidade = "1";
			this.valorUnitario = "";
			this.idItem = "";
			// this.$store.state.produtoSelecionado = null;
			this.$store.state.carregando = false;
		},

		cancelarEdicao() {
			this.$emit("cancelarEdicao");
			this.idItem = "";
			this.quantidade = "1";
			this.$store.state.editarProduto = false;
			this.$store.commit("selecionarProduto", null);
			document.getElementById("js-id-produto").readOnly = false;
			document.getElementById("js-id-produto").disabled = false;
			document.getElementById("js-btn-cancelar").style.display = "none";
			document.getElementById("tipoVenda") ? document.getElementById("tipoVenda").style.display = "block" : null;
			document.getElementById("btn-baixa") ? document.getElementById("btn-baixa").style.display = "block" : null;
			document.getElementById("js-id-produto").focus();
		},

		cancelarVenda() {
			this.$store.state.produtoSelecionado = null;
			this.$emit("cancelarVenda");
		},

		finalizarVenda() {
			this.$store.state.produtoSelecionado = null;
			if (!this.$store.state.Venda || !this.$store.state.Venda.cliente) {
				return this.$store.commit("relatarErro", { mensagem: "É necessário informar o cliente para prosseguir" });
			}
			if (!this.$store.state.Venda.vendedor || !this.$store.state.Venda.vendedor.id) {
				return this.$emit("trocarVendedor");
			}
			this.$emit("finalizarVenda", this.itens);
		},

		alterarTipoVenda() {
			const cdTipo = this.$route.query.tipoVenda == "S" ? "C" : "S";
			this.$store.state.produtoSelecionado = null;
			if (this.$route.query.baixarCondicional) {
				return;
			}
			const itensVenda = this.$store.state.itensVenda;
			let limiteCondicional = this.$store.state.situacaoCliente?.limiteCondicional;

			let qtdItens = 0;
			itensVenda.forEach(item => {
				qtdItens += item.quantidade;
			});
			if(qtdItens > limiteCondicional) {
				if(this.$route.query.tipoVenda == "S"){
					if(!this.$store.state.Venda.Liberacao) {
						this.$store.state.Venda.Liberacao = {
							cliente: {naoLibera: false}
						};
					}
					this.$store.state.Venda.Liberacao.cliente.naoLibera = true;
					return this.$emit("podeVenderCondicional", cdTipo, {
						...this.$store.state.Venda.cliente,
						alterarTipoVenda: true,
					});
				}
			}
			if (document.getElementById("tipoVenda").innerText == "Condicional") {
				if ((this.$store.state.Venda.cliente.id == this.$store.state.ClientePadrao.id) && !this.$store.state.Usuario.condicionalConsumidorFinal) {
					return this.$store.commit("relatarErro", {
						tipo: "polar",
						mensagem: "Não é possível vender condicional para consumidor final. \nDeseja selecionar o cliente?",
						item: {
							condicional: true,
							cdTipo: "C",
							selecionarCliente: true
						}
					});
					// return this.$emit("trocarCliente");
				}
				return this.$store.commit("relatarErro", {
					tipo: "polar",
					mensagem: "Tem certeza que deseja alterar a venda para condicional?",
					item: {
						condicional: true,
						cdTipo: "C"
					}
				});
			} else {
				return this.$store.commit("relatarErro", {
					tipo: "polar",
					mensagem: "Tem certeza que deseja alterar a venda para não condicional?",
					item: {
						condicional: true,
						cdTipo: "S"
					}
				});
			}
		},

		eventoTeclado(e) {
			if (`${e.key}`.toUpperCase() == "J" && e.ctrlKey) e.preventDefault();
			this.selecionarFilhoSeta(e);
		},
		eventBusSelecionarItem({ id, quantidade }) {
			if (id || quantidade) {
				setTimeout(() => {
					this.idItem = id;
					this.quantidade = quantidade || "1";
					document.getElementById("js-id-produto").value = id;
					if (document.getElementById("js-id-produto").disabled) {
						document.getElementById("js-quant").focus();
						setTimeout(() => {
							document.getElementById("js-quant").select();
						}, 10);
					}
				}, 50);
			} else if (!id) {
				this.idItem = "";
			}
		},
		proximoInput(id) {
			if (!id) return alert("Falta o id do próximo input!");
			const input = document.getElementById(id);
			input.focus();
			if (input.select) {
				input.select();
			}
		},
		solicitaInserirDevolver() {
			this.baixarCondicional = !this.baixarCondicional;
			this.$store.state.inserirProduto = !this.$store.state.inserirProduto;
			document.getElementById("js-id-produto").focus();
		},

		verificarResultadoLeitor(data) {
			if (data.codeResult) {
				if (data.codeResult.code.length) {
					this.$store.state.leitor = false;
					this.idItem = data.codeResult.code;
					Quagga.offDetected();
					setTimeout(() => {
						this.adicionarProduto();
					}, 450);
				}
			}
		},

		abrirLeitorCodigoBarras() {
			this.$store.state.leitor = true;
			setTimeout(() => {
				Quagga.init({
					inputStream: {
						name: "Live",
						type: "LiveStream",
						target: document.querySelector("#barcode-scanner"),
					},
					decoder: {
						readers: ["code_128_reader"]
					},
					constraints: {
						width: 320,
					},
				}, (err) => {
					if (err) {
						console.log(err);
						return;
					}
					Quagga.onDetected((data) => {
						if (data.codeResult) {
							if (data.codeResult.code.length) {
								this.$store.state.leitor = false;
								Quagga.offDetected();
								Quagga.stop();
								setTimeout(() => {
									this.idItem = data.codeResult.code;
									this.adicionarProduto();
								}, 150);
							}
						}
					});
					Quagga.start();
				});
				this.$store.state.carregando = false;
			}, 120);
		},

		fecharLeitor() {
			this.$store.state.leitor = null;
			Quagga.offDetected();
			Quagga.stop();
		},

		async verificarMediaDevices() {
			if (!navigator.mediaDevices) {
				return this.podeLerCodigo = false;
			}
			const devices = await navigator.mediaDevices.enumerateDevices();
			if (devices.filter(device => device.kind == "videoinput").length) {
				this.podeLerCodigo = true;
			} else {
				this.podeLerCodigo = false;
			}
		},
	},
	mounted() {
		this.$bus.$on("lancamento/cancelarEdicao", this.cancelarEdicao);
		this.$store.state.leitor = false;
		this.verificarMediaDevices();
		this.$bus.$on("selecionarItem", this.eventBusSelecionarItem);
		if (this.$route.query.baixarCondicional) {
			this.baixarCondicional = true;
			this.$store.state.inserirProduto = !this.$route.query.baixarCondicional;
			// this.$store.state.inserirProduto = !(this.$store.state.Usuario.supervisor || this.$store.state.Usuario.caixa || this.$store.state.Usuario.usuarioPrivilegiado);
			if(
				this.$route.query.alteracao && 
				(!this.$store.state.Usuario.supervisor || !this.$store.state.Usuario.usuarioPrivilegiado)
			) {
				this.$store.state.inserirProduto = false;
			}
		}
		if (this.$route.query.tipoVenda == "C") {
			this.cdTipo = "C";
		}
		document.addEventListener("keydown", this.eventoTeclado);
	},

	destroyed() {
		this.$route.query.tipoVenda = this.$store.state.Venda?.tipoVenda || "S";
		this.$bus.$off("lancamento/cancelarEdicao", this.cancelarEdicao);
		this.$bus.$off("selecionarItem", this.eventBusSelecionarItem);
		document.removeEventListener("keydown", this.eventoTeclado);
	}
};
</script>
<style scoped>
.sm {
	height: 30px;
}

.inputControle {
	padding: 5px;
	width: 90%;
}

.fas.fa-times,
.fa.fa-check {
	color: var(--branco);
}

.fa-external-link-alt {
	color: var(--primario);
}

.text-justify {
	text-align: justify;
}

#js-btn-cancelar {
	display: none;
}

.descricao-produto {
	width: 96%;
	display: flex;
	flex-grow: 1;
}

#barcode-scanner-container {
	position: relative;
	width: 100%;
	height: 100%;
	margin-top: 10px;
	overflow: auto;
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-content: center;
}

#barcode-scanner {
	position: absolute;
	display: flex;
	top: 0;
	margin: 0px;
	height: auto;
	margin-top: 3px;
}

canvas.drawingBuffer {
	position: absolute;
	left: 0;
}

.containerTabelaFilhos{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

.containerMensagem{
	width: 100%;
	margin-top: 15px;
	height: 5vh;
	background-color: rgb(248, 188, 188);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border: 2px solid red;
	border-radius: 10px;

	opacity: 0.5;
	animation: pisca 1.3s infinite ease-in;
}

.containerMsgProdRepetido i {
	color: #00b4d8;
}

.containerMsgProdRepetido{
	width: 75%;
	height: auto;
	display: flex;
	flex-direction: row;;
	background-color: #caf0f8;
	border: 2px solid #00b4d8;
	border-radius: 10px;
	color: #00b4d8;
}
.containerModalProdutosRepetido{
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
}

@keyframes pisca {
	0%{
		opacity: 0.5;
	}
	50%{
		opacity: 1;
	}
	100%{
		opacity: 0.5;
	}
}


#barcode-scanner video,
canvas {
	width: 100%;
	height: auto;
}

#btn-cancelar-barcode-scanner {
	position: absolute;
	bottom: 10px;
	left: 10%;
	right: 10%;
}

@media screen and (max-width: 1175px) and (min-width: 768px) {
	.descricao-produto {
		font-size: var(--txt-medio);
	}

	.row.between.w-100 {
		font-size: var(--txt-pequeno);
	}

	.btn {
		font-size: var(--txt-medio);
		padding: 0px 5px;
		text-align: center;
		align-self: center;
		height: auto;
		min-height: 40px;
	}
}

@media screen and (max-width: 768px) {
	.btn {
		width: 80%;
		font-size: var(--txt-medio);
		padding: 2px;
		text-align: center;
		align-self: center;
		height: auto;
	}

	.row.between.w-100 {
		flex-direction: column;
	}

	.descricao-produto {
		font-size: var(--txt-normal);
	}

	.row.between.w-100 .col.center.m-2 {
		width: 100%;
	}
}

@media screen and (max-width: 380px) {
	* {
		font-size: var(--txt-pequeno) !important;
	}
}

@media screen and (max-height: 595px) {
	.descricao-produto {
		font-size: var(--txt-pequeno);
	}

	.btn {
		width: 80%;
		font-size: var(--txt-medio);
		text-align: center;
		align-self: center;
		height: auto;
		min-height: auto;
	}
}
</style>
